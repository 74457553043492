import { useAuth0 } from "@auth0/auth0-react";
import CustomerAttributeDomain, {
  AttributeValueType,
} from "entities/domain/customers/contact-attribute-domain";
import { useCallback } from "react";
import {
  addCustomerAttributeSuccess,
  deleteCustomerAttributeSuccess,
} from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService, {
  CreateContactAttributePayload,
} from "services/contacts";

export default function useCustomerAttributesStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const addCustomerAttributeWaterfall =
    (customerId: number, payload: CreateContactAttributePayload) =>
    async (): Promise<
      CustomerAttributeDomain<AttributeValueType> | undefined
    > => {
      try {
        const customerAttribute: CustomerAttributeDomain<AttributeValueType> =
          await ContactsService.addCustomerAttribute(
            auth0Context,
            merchant.groupId,
            customerId,
            {
              attribute_id: payload.attribute_id,
              value_string: payload.value_string,
              value_integer: payload.value_integer,
              value_float: payload.value_float,
              value_boolean: payload.value_boolean,
              value_datetime: payload.value_datetime,
            }
          );

        dispatch(
          addCustomerAttributeSuccess({ customerId, customerAttribute })
        );

        return customerAttribute;
      } catch (error: unknown) {
        return undefined;
      }
    };

  const addCustomerAttributeAsync = useCallback(
    (customerId: number, payload: CreateContactAttributePayload) =>
      addCustomerAttributeWaterfall(customerId, payload)(),
    [dispatch, merchant.groupId]
  );

  const deleteCustomerAttributeWaterfall =
    (customerId: number, customerAttributeId: string, attributeId: string) =>
    async (): Promise<boolean> => {
      try {
        await ContactsService.deleteCustomerAttribute(
          auth0Context,
          merchant.groupId,
          customerId,
          customerAttributeId!
        );

        dispatch(deleteCustomerAttributeSuccess({ customerId, attributeId }));

        return true;
      } catch (error: unknown) {
        return false;
      }
    };

  const deleteCustomerAttributeAsync = useCallback(
    (customerId: number, customerAttributeId: string, attributeId: string) =>
      deleteCustomerAttributeWaterfall(
        customerId,
        customerAttributeId,
        attributeId
      )(),
    [dispatch, merchant.groupId]
  );

  return {
    addCustomerAttribute: addCustomerAttributeAsync,
    deleteCustomerAttribute: deleteCustomerAttributeAsync,
  };
}
