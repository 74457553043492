import { AttributeType } from "../attributes/attribute-domain";

export type AttributeValueType = string | number | boolean | Date | undefined;

export default class CustomerAttributeDomain<T extends AttributeValueType> {
  id: string;

  attributeId: string;

  type: AttributeType | undefined;

  value: T;

  createdAt: string;
  updatedAt: string;

  constructor(
    id: string,
    attributeId: string,
    value: T,
    type: AttributeType | undefined,
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id;
    this.attributeId = attributeId;
    this.value = value;
    this.type = type;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
