import WhatsappProfile, {
  MessagingLimit,
  WhatsappBusinessVertical,
} from "entities/domain/whatsapp";
import { WhatsappProfileDTO } from "entities/dto/WhatsappProfileDTO";

export function whatsappProfileTransformFromDtoToDomain(
  whatsappProfileDTO: WhatsappProfileDTO
): WhatsappProfile {
  return new WhatsappProfile(
    whatsappProfileDTO.phone_number || "",
    whatsappProfileDTO.email || "",
    whatsappProfileDTO.vertical as WhatsappBusinessVertical,
    whatsappProfileDTO.profile_picture_url || "",
    whatsappProfileDTO.about_text || "",
    whatsappProfileDTO.address || "",
    whatsappProfileDTO.description || "",
    whatsappProfileDTO.websites || [],
    (whatsappProfileDTO.current_limit as MessagingLimit) ||
      MessagingLimit.TIER_250,
    whatsappProfileDTO.current_quality_rating || ""
  );
}
