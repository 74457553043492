import {
  Button,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { IoFunnelSharp, IoPeople } from "react-icons/io5";

interface MobileAnalyticsTabsProps {
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

const MobileAnalyticsTabs = ({
  tabIndex,
  setTabIndex,
}: MobileAnalyticsTabsProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return !isBaseSize ? null : (
    <HStack spacing={0} w="100%">
      <Button
        variant="ghost"
        borderRadius={0}
        py={6}
        background="inherit"
        _active={{
          background: "inherit",
        }}
        borderBottomWidth="2px"
        borderBottomStyle="solid"
        borderBottomColor={
          tabIndex !== 0
            ? colorMode === "dark"
              ? "gray.600"
              : "gray.300"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
        colorScheme={colorScheme}
        isActive={tabIndex === 0}
        w="100%"
        onClick={() => {
          setTabIndex(0);
        }}
        color={
          tabIndex !== 0
            ? colorMode === "dark"
              ? "gray.200"
              : "gray.600"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.600`
        }
      >
        <Icon
          as={IoPeople}
          mr={2}
          width="1rem"
          height="1rem"
          fill={
            tabIndex !== 0
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        />
        <Text>Agents</Text>
      </Button>
      <Button
        w="100%"
        py={6}
        borderRadius={0}
        variant="ghost"
        background="inherit"
        borderBottomWidth="2px"
        _active={{
          background: "inherit",
        }}
        borderBottomStyle="solid"
        borderBottomColor={
          tabIndex !== 1
            ? colorMode === "dark"
              ? "gray.600"
              : "gray.300"
            : colorMode === "dark"
            ? `${colorScheme}.200`
            : `${colorScheme}.500`
        }
        colorScheme={colorScheme}
        isActive={tabIndex === 1}
        onClick={() => {
          setTabIndex(1);
        }}
      >
        <Icon
          as={IoFunnelSharp}
          mr={2}
          width="1rem"
          height="1rem"
          fill={
            tabIndex !== 1
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        />
        <Text
          color={
            tabIndex !== 1
              ? colorMode === "dark"
                ? "gray.200"
                : "gray.600"
              : colorMode === "dark"
              ? `${colorScheme}.200`
              : `${colorScheme}.600`
          }
        >
          Leads
        </Text>
      </Button>
    </HStack>
  );
};

export default MobileAnalyticsTabs;
