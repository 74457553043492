import { StyleConfig } from "@chakra-ui/theme-tools";

const NumberInputStyles = {
  baseStyle: {
    root: {
      borderRadius: "full",
      overflow: "hidden",
      width: "100%",
    },
    stepperGroup: {
      height: "100%",
      margin: 0,
    },
    stepper: {
      border: "none",
      pr: "0.25rem",
    },
  },
  variants: {
    solid: ({ colorMode }) => ({
      field: {
        bg: colorMode === "dark" ? "gray.900" : "gray.50",
      },
      stepper: {
        color: colorMode === "dark" ? "white" : "gray.500",
      },
    }),
  },
  defaultProps: {
    size: "md",
    variant: "solid",
    colorScheme: "blue",
  },
} as StyleConfig;

export default NumberInputStyles;
