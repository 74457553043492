import Select, { SingleValue, components } from "react-select";
import {
  AttributeType,
  AttributeTypeDisplayNames,
} from "entities/domain/attributes/attribute-domain";
import React from "react";
import { getReactSelectStyles } from "util/methods";
import { useColorMode } from "@chakra-ui/color-mode";
import { useAppSelector } from "redux/hooks";

interface AttributeTypeSelectProps {
  attributeTypeToShow?: AttributeType | null;
  isDisabled: boolean;
  hasError?: boolean;
  onChange?: (selectedOption: AttributeType | null) => void;
}

interface TypeOption {
  value: AttributeType;
  label: string;
  example?: string;
}

const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <span>{data.label}</span>
      {data.example && (
        <small style={{ color: "gray", marginLeft: "auto" }}>
          {data.example}
        </small>
      )}
    </div>
  );
};

const AttributeTypeSelect = ({
  attributeTypeToShow,
  isDisabled,
  hasError = false,
  onChange,
}: AttributeTypeSelectProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const typeOptions: TypeOption[] = Object.entries(
    AttributeTypeDisplayNames
  ).map(([key, { displayName, example }]) => ({
    value: key as AttributeType,
    label: displayName,
    example,
  }));

  return (
    <Select
      isMulti={false}
      value={typeOptions.find((option) => option.value === attributeTypeToShow)}
      onChange={
        onChange &&
        ((option: SingleValue<TypeOption>) => onChange(option?.value || null))
      }
      isClearable={false}
      options={typeOptions}
      isDisabled={isDisabled}
      aria-invalid={hasError}
      styles={getReactSelectStyles(colorMode, colorScheme)}
      components={{ Option: CustomOption }}
    />
  );
};

export default AttributeTypeSelect;
