import { useAuth0 } from "@auth0/auth0-react";
import React, {
  Avatar,
  Box,
  Icon,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as CloseLockIcon } from "assets/icons/close-lock.svg";
import { ReactComponent as ExportIcon } from "assets/icons/download.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as HandPointer } from "assets/icons/hand-point-svgrepo-com.svg";
import { ReactComponent as OpenLockIcon } from "assets/icons/open-lock.svg";
import ExportToEmailModal from "components/modals/ExportToEmail";
import FuzeyPopover, { PopoverAction } from "components/shared/FuzeyPopover";
import useConversationsStore from "hooks/use-conversations-store";
import useMessagesStore from "hooks/use-messages-store";
import { memo, useCallback, useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
  updateConversationIsSubscribed,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";

const PopoverActions = memo(() => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { search } = useLocation();
  const { activeConversation, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { closeOrOpenConversation } = useConversationsStore();
  const { markConversationAsRead, markConversationAsUnread } =
    useMessagesStore();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const onExportToEmailClose = useCallback(() => setShowExportModal(false), []);
  const [popoverActions, setPopoverActions] = useState<PopoverAction[]>([]);

  useEffect(() => {
    const newPopoverActions: PopoverAction[] = [];

    if (!activeConversation) {
      setPopoverActions(newPopoverActions);

      return;
    }

    if (activeConversation.messageId) {
      newPopoverActions.push(
        activeConversation.unreadCount
          ? {
              icon: EyeIcon,
              name: "Mark Read",
              callback: () => {
                markConversationAsRead(
                  activeConversation.id,
                  activeConversation.messageId,
                  currentAgent!.id
                );
              },
            }
          : {
              icon: EyeOffIcon,
              name: "Mark Unread",
              callback: () => {
                if (activeConversationId) {
                  navigate({
                    pathname: `/${merchant.id}/inbox/`,
                    search: createSearchParams(search).toString(),
                  });
                }
                setTimeout(() => {
                  markConversationAsUnread(
                    activeConversation.id,
                    activeConversation.messageId,
                    currentAgent!.id
                  );
                }, 250);
              },
            }
      );
    }

    newPopoverActions.push({
      icon: activeConversation.isOpen ? CloseLockIcon : OpenLockIcon,
      name: `Mark ${activeConversation.isOpen ? "Closed" : "Open"}`,
      callback: async () => {
        const updatedConversation = await closeOrOpenConversation(
          activeConversation.id,
          !activeConversation.isOpen,
          currentAgent!.id
        );

        if (!updatedConversation) {
          toast({
            status: "error",
            title: `Failed to ${
              activeConversation.isOpen ? "close" : "open"
            } conversation`,
          });

          return;
        }

        if (updatedConversation.isOpen) {
          navigate({
            pathname: `/${merchant.id}/inbox/${updatedConversation.id}`,
            search: createSearchParams(search).toString(),
          });
          dispatch(
            setActiveConversationOpenClosedFilter(OpenClosedFilter.Open)
          );
        } else {
          navigate({
            pathname: `/${merchant.id}/inbox`,
            search: createSearchParams(search).toString(),
          });
        }
      },
    });

    newPopoverActions.push({
      icon: () => (
        <Avatar
          width="auto"
          height="auto"
          position="relative"
          background="transparent"
          icon={
            <Icon
              as={HandPointer}
              boxSize="1rem"
              __css={{
                path: {
                  stroke:
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.600`,
                },
              }}
            />
          }
        >
          {!activeConversation.isSubscribed ? null : (
            <Box
              position="absolute"
              mb={-1}
              h="100%"
              w="1px"
              transform="rotate(-45deg)"
              bgColor={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.600`
              }
            />
          )}
        </Avatar>
      ),
      name: `Mark ${
        activeConversation.isSubscribed ? "unsubscribed" : "subscribed"
      }`,
      callback: async () => {
        const isCurrentlySubscribed = activeConversation.isSubscribed;

        try {
          await ContactsService.updateContactChannel(
            auth0Context,
            merchant.groupId,
            activeConversation.customerId,
            activeConversation.customerChannelId,
            {
              is_subscribed: !isCurrentlySubscribed,
            }
          );

          dispatch(
            updateConversationIsSubscribed({
              conversationId: activeConversation.id,
              isSubscribed: !isCurrentlySubscribed,
            })
          );

          toast({
            status: "success",
            title: `Successfully ${
              isCurrentlySubscribed ? "unsubscribed from" : "subscribed to"
            } marketing!`,
          });
        } catch (_error: unknown) {
          toast({
            status: "error",
            title: `Failed to ${
              isCurrentlySubscribed ? "unsubscribe from" : "subscribe to"
            } marketing`,
          });
        }
      },
    });

    newPopoverActions.push({
      icon: ExportIcon,
      name: `Export`,
      callback: () => {
        setShowExportModal(true);
      },
    });

    setPopoverActions(newPopoverActions);
  }, [activeConversation, currentAgent, colorScheme, colorMode]);

  return (
    <>
      <FuzeyPopover
        triggerSize="md"
        triggerIcon={<FiMoreVertical />}
        actions={popoverActions}
      />
      <ExportToEmailModal
        isOpen={showExportModal}
        onClose={onExportToEmailClose}
        conversationId={activeConversation!.id}
      />
    </>
  );
});

export default PopoverActions;
