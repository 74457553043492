import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from "assets/icons/Plus-ds.svg";
import { ReactComponent as CalendarButtonIcon } from "assets/icons/calendar-2.svg";
import MagicWand from "components/shared/MagicWand";
import React, { RefObject, useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as CreditCardButtonIcon } from "assets/icons/credit-card-2.svg";
import { ReactComponent as PaperClipButtonIcon } from "assets/icons/paperclip-light.svg";
import { ReactComponent as StarButtonIcon } from "assets/icons/star-empty.svg";
import NewEventModal from "components/calendar/NewEventModal";
import PaymentsPopover from "components/chat/shared/payments-popover";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import FileSelector from "components/shared/FileSelector";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { ReviewProvider } from "entities/domain/reviews/review-domain";
import TemplateDomain from "entities/domain/templates";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { activeConversationTemplatesSelector } from "redux/features/conversations";
import { messagesSelector } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { REVIEW_INVITATION_TEMPLATE_IDS } from "util/constants";
import { isLondonLocksMerchant, maxAllowedSize } from "util/methods";
import { canSendPayments } from "util/permissions";

interface MoreActionsMenuProps {
  message: string | undefined;
  isDisabled?: boolean;
  setFile: (url: string | null, type: string | null, id: string | null) => void;
  updateEditorText: (m: string) => void;
  setIsLoadingSuggestion: (loading: boolean) => void;
}

const MoreActionsMenu = ({
  message,
  isDisabled = false,
  setFile,
  updateEditorText,
  setIsLoadingSuggestion,
}: MoreActionsMenuProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const conversationTemplates = useAppSelector(
    activeConversationTemplatesSelector
  );
  const {
    isLoadingActiveConversation,
    activeConversation,
    activeConversationId,
  } = useAppSelector((state) => state.conversations);
  const conversationMessages = useAppSelector(messagesSelector);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const fileSelector = useDisclosure();

  const [reviewTemplate, setReviewTemplate] = useState<TemplateDomain>();
  const [openEventForm, setOpenEventForm] = useState<boolean>(false);

  useEffect(() => {
    setReviewTemplate(
      conversationTemplates.find((t) =>
        REVIEW_INVITATION_TEMPLATE_IDS.includes(t.id)
      )
    );
  }, [conversationTemplates]);

  const [showPaymentsPopover, setShowPaymentsPopover] =
    useState<boolean>(false);
  const [showPaymentWithoutInvoicePrompt, setShowPaymentWithoutInvoicePrompt] =
    useState<boolean>(false);

  const isSendImageAllowed = (): boolean =>
    !activeConversation?.isTemplatesOnly() &&
    activeConversation?.channel !== ConversationChannel.ZAPIER_FACEBOOK;

  const isDarkMode = colorMode === "dark";
  const activeIconColor = isDarkMode ? `${colorScheme}.200` : "gray.500";
  const disabledIconColor = isDarkMode ? `${colorScheme}.700` : "gray.100";

  const magicWandRef = useRef<HTMLButtonElement | undefined>();

  return (
    <>
      <Tooltip label="More actions" isDisabled={isBaseSize}>
        <Menu>
          <MenuButton
            size="xs"
            as={Button}
            variant="ghost"
            p={0}
            alignSelf="center"
            isDisabled={isDisabled}
            sx={{
              "> span": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              },
            }}
          >
            <Icon
              as={PlusIcon}
              width="1rem"
              height="1rem"
              __css={{
                path: {
                  fill:
                    colorMode === "light"
                      ? `${colorScheme}.500`
                      : `${colorScheme}.200`,
                },
                rect: {
                  fill: "transparent",
                },
              }}
            />
          </MenuButton>
          <MenuList zIndex="99999">
            <MenuItem
              gridGap={3}
              isDisabled={
                isDisabled ||
                !isSendImageAllowed() ||
                isLoadingActiveConversation
              }
              onClick={() => {
                if (isDisabled) {
                  return;
                }

                fileSelector.onOpen();
              }}
            >
              <Icon
                as={PaperClipButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Add attachment
            </MenuItem>
            <MenuItem
              gridGap={3}
              isDisabled={isLoadingActiveConversation}
              onClick={() => setOpenEventForm(true)}
            >
              <Icon
                as={CalendarButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Create event
            </MenuItem>
            <MenuItem
              px="0.8rem"
              py="0.4rem"
              _hover={{
                bg: colorMode === "dark" ? "gray.600" : "gray.100",
              }}
              gridGap={3}
              onClick={() => {
                if (
                  !merchant.isIntegrationEnabled(IntegrationName.QUICKBOOKS) &&
                  !merchant.isIntegrationEnabled(IntegrationName.XERO) &&
                  isLondonLocksMerchant(merchant.id)
                ) {
                  setShowPaymentWithoutInvoicePrompt(true);
                } else {
                  setTimeout(() => {
                    setShowPaymentsPopover(true);
                  }, 0);
                }
              }}
              isDisabled={
                !merchant.isPaymentEnabled() ||
                !canSendPayments(merchant.id, currentAgent!) ||
                isLoadingActiveConversation
              }
            >
              <Icon
                as={CreditCardButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Request payment
            </MenuItem>
            <MenuItem
              gridGap={3}
              onClick={() => {
                InboxService.requestReview(
                  auth0Context,
                  merchant.id,
                  activeConversationId!,
                  ReviewProvider.GOOGLE
                );
              }}
              isDisabled={!reviewTemplate || isLoadingActiveConversation}
            >
              <Icon
                as={StarButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: isDisabled ? disabledIconColor : activeIconColor,
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
              Request review
            </MenuItem>
            <MenuItem
              gridGap={3}
              isDisabled={isDisabled || !message}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                magicWandRef?.current?.click();
              }}
            >
              <MagicWand
                ref={magicWandRef as RefObject<HTMLButtonElement>}
                lastMessage={
                  conversationMessages
                    ? [...conversationMessages].pop()
                    : undefined
                }
                message={message}
                setMessage={updateEditorText}
                setIsLoading={setIsLoadingSuggestion}
              />
              Rephrase with AI
            </MenuItem>
          </MenuList>
        </Menu>
      </Tooltip>
      <ConfirmationDialog
        headerText="No invoice attached to payment"
        messageText="Please note that an invoice will not be attached to this payment. To enable invoicing, please reconnect your accounting software with Fuzey."
        buttonText="Proceed"
        isOpen={showPaymentWithoutInvoicePrompt}
        setIsOpen={setShowPaymentWithoutInvoicePrompt}
        confirmationCallback={() => {
          setShowPaymentWithoutInvoicePrompt(false);
          setShowPaymentsPopover(true);
        }}
      />
      <FileSelector
        fileTypeAccept={activeConversation?.getAcceptedFileFormat()}
        isOpen={fileSelector.isOpen}
        enableUrlShortening={
          activeConversation?.channel === ConversationChannel.SMS
        }
        onClose={fileSelector.onClose}
        onFileSelect={async (selectedFile) => {
          if (!selectedFile) {
            setFile(null, null, null);
            return;
          }

          setFile(selectedFile.url, selectedFile.type, selectedFile.id);
          fileSelector.onClose();
        }}
      />
      <Modal
        size={isBaseSize ? "full" : "lg"}
        isOpen={showPaymentsPopover}
        onClose={() => {
          setShowPaymentsPopover(false);
        }}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <PaymentsPopover
            id="payments-popover"
            onClose={() => setShowPaymentsPopover(false)}
          />
        </ModalContent>
      </Modal>
      {openEventForm &&
        (isBaseSize ? (
          <Drawer
            isOpen={openEventForm}
            onClose={() => setOpenEventForm(false)}
            placement="top"
            isFullHeight={true}
            allowPinchZoom={true}
            autoFocus={false}
          >
            <DrawerOverlay />
            <DrawerContent overflowY="scroll" borderRadius="0 !important" p={5}>
              <NewEventModal
                openEventForm={openEventForm}
                closeNewEventForm={() => setOpenEventForm(false)}
                customerId={activeConversation?.customerId}
                defaultChannelId={activeConversation?.customerChannelId}
              />
            </DrawerContent>
          </Drawer>
        ) : (
          <NewEventModal
            openEventForm={openEventForm}
            closeNewEventForm={() => setOpenEventForm(false)}
            customerId={activeConversation?.customerId}
            defaultChannelId={activeConversation?.customerChannelId}
          />
        ))}
    </>
  );
};

export default MoreActionsMenu;
