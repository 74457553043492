import {
  Button,
  Flex,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  UnorderedList,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as FailedIcon } from "assets/icons/failed-status.svg";
import { ReactComponent as GreyTickIcon } from "assets/icons/grey-tick.svg";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  StatusIcon,
} from "entities/domain/conversations/message-domain";
import React from "react";
import { useAppSelector } from "redux/hooks";

export default ({
  activeConversation,
  message,
}: {
  message: MessageDomain;
  activeConversation?: ConversationDomain;
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.IN_PROGRESS
  ) {
    return (
      <Icon
        as={GreyTickIcon}
        boxSize="1rem"
        fill={
          colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.700`
        }
      />
    );
  }

  if (
    activeConversation &&
    [StatusIcon.SUCCESS, StatusIcon.DELIVERED].includes(
      message.getStatusIcon(activeConversation.channel)
    )
  ) {
    const iconColor =
      message.getStatusIcon(activeConversation.channel) === StatusIcon.SUCCESS
        ? "white"
        : colorMode === "dark"
        ? `${colorScheme}.600`
        : `${colorScheme}.700`;

    return (
      <Flex alignItems="center" justifyContent="center">
        <Icon
          as={GreyTickIcon}
          boxSize={4}
          __css={{ path: { fill: iconColor } }}
        />
        <Icon
          as={GreyTickIcon}
          boxSize={4}
          __css={{ path: { fill: iconColor } }}
          ml={-2}
        />
      </Flex>
    );
  }

  if (
    activeConversation &&
    message.getStatusIcon(activeConversation.channel) === StatusIcon.FAILED
  ) {
    if (message.metadata && message.metadata.error_code) {
      return (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Button variant="ghost" size="xs" colorScheme="red">
              <Icon
                as={FailedIcon}
                className="statusIcon"
                __css={{
                  path: {
                    fill: colorMode === "dark" ? "red.600" : "red.400",
                  },
                }}
              />
              <Text ml={2} color={colorMode === "dark" ? "red.600" : "red.400"}>
                Not delivered
              </Text>
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                {activeConversation.channel === ConversationChannel.WHATSAPP &&
                message.isUnreachable() ? (
                  <VStack>
                    <Text>Unable to deliver message. Reasons can include:</Text>
                    <UnorderedList fontSize="smaller">
                      <ListItem>
                        The recipient phone number is not a WhatsApp phone
                        number.
                      </ListItem>
                      <ListItem>
                        Recipient has not accepted WhatsApp’s new Terms of
                        Service and Privacy Policy.
                      </ListItem>
                      <ListItem>
                        Recipient using an old WhatsApp version.
                      </ListItem>
                    </UnorderedList>
                  </VStack>
                ) : (
                  <Text fontSize="smaller">{message.getFailedReason()}</Text>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      );
    }

    return (
      <Flex justifyContent="center" alignItems="center">
        <Icon
          id="status-icon-not-delivered"
          as={FailedIcon}
          className="statusIcon"
          __css={{
            path: {
              fill: colorMode === "dark" ? "red.600" : "red.400",
            },
          }}
        />
        <Text
          ml={2}
          id="status-not-delivered-text"
          color={colorMode === "dark" ? "red.600" : "red.400"}
        >
          Not delivered
        </Text>
      </Flex>
    );
  }

  return null;
};
