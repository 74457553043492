import { Flex, useBreakpointValue } from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import React from "react";
import { format } from "date-fns";
import DateFilter from "./DateFilter";
import CSVExportButton from "./csvExportButton";

interface AnalyticsAgentReport {
  name: string;
  uniqueConversations: number | null;
  messagesSent: number | null;
  avgTimeToReplyInSeconds: string | null;
}

interface AnalyticsTopBarProps {
  fromDate: Date | null;
  toDate: Date | null;
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
  mappedReports: AnalyticsAgentReport[];
  tabIndex: number;
  isLoading: boolean;
}

const AnalyticsTopBar: React.FC<AnalyticsTopBarProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  mappedReports,
  isLoading,
  tabIndex,
}: AnalyticsTopBarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const exportColumns = [
    "name",
    "uniqueConversations",
    "messagesSent",
    "avgTimeToReply",
  ] as Array<keyof AnalyticsAgentReport>;
  const exportFileName = `analytics_${
    fromDate ? format(fromDate, "yyyy-MM-dd") : "unknown"
  }_to_${toDate ? format(toDate, "yyyy-MM-dd") : "unknown"}.csv`;
  return isBaseSize ? (
    <Topbar title="Analytics" />
  ) : (
    <Flex
      pl="10rem"
      pr={4}
      justifyContent="start"
      flexWrap="wrap"
      gridGap={4}
      alignItems="center"
      width="100%"
    >
      <DateFilter
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />{" "}
      {tabIndex === 0 ? (
        <CSVExportButton
          data={mappedReports}
          columns={exportColumns}
          filename={exportFileName}
          isDisabled={!fromDate || !toDate || isLoading}
        />
      ) : null}
    </Flex>
  );
};

export default AnalyticsTopBar;
