import { InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface InfoModalWhatsappProps {}

const InfoModalWhatsapp: React.FC<InfoModalWhatsappProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onOpen();
        }}
        variant="ghost"
        colorScheme="gray"
        borderRadius="full"
        h="auto"
        w="auto"
        minW="auto"
        p={0}
        zIndex={101}
      >
        <Icon as={InfoIcon} boxSize={5} />
      </Button>

      <Modal
        size={isBaseSize ? "full" : "md"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>It’s time to use a template!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              It’s been 24 hours since this contact last messaged you and the
              free text window is now closed. To reopen the conversation, please
              send them an approved WhatsApp template. Once they’ve replied, the
              free text window will reopen.
            </Text>
            <Text fontSize="sm" mt={4}>
              For more information, please see the 📄💼 WhatsApp for Business
              messaging policy
              <Link
                ml={1}
                href="https://business.whatsapp.com/policy"
                isExternal
              >
                here.
              </Link>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme={colorScheme} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InfoModalWhatsapp;
