import {
  Box,
  Divider,
  Flex,
  Heading,
  SkeletonCircle,
  SkeletonText,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import SmartList from "components/shared/SmartList";
import Select, { MultiValue } from "react-select";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  convertSecondsToHoursMinutesSeconds,
  getReactSelectStyles,
  sortObjects,
} from "util/methods";
import AgentOption, {
  AvailableAgentOption,
} from "components/shared/AgentOption";
import AgentDomain from "entities/domain/agents/agent-domain";

const AgentNameColumn = ({ item }: { item: ReportPerAgentDomain }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { agents } = useAppSelector((state) => state.agents);

  const agent = agents.find((a) => a.id === item.agentId);

  return !agent ? null : (
    <Flex justifyContent="start" alignItems="center" gridGap={2}>
      {!isBaseSize && (
        <ProfileAvatar
          profilePicture={agent.getPicture()}
          name={agent.getFullName()}
        />
      )}

      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        textAlign="left"
        ml={isBaseSize ? 0 : 4}
      >
        <Text fontWeight={700}> {agent.getFullName()}</Text>
        <Text display={isBaseSize ? "none" : "initial"}> {agent.email}</Text>
      </Flex>
    </Flex>
  );
};

const UniqueConversationsColumn = ({
  item,
}: {
  item: ReportPerAgentDomain;
}) => (
  <Text>
    {item.uniqueConversations === null ? "N/A" : item.uniqueConversations}
  </Text>
);

const MessagesSentColumn = ({ item }: { item: ReportPerAgentDomain }) => (
  <Text>{item.messagesSent === null ? "N/A" : item.messagesSent}</Text>
);

const AverageTimeToReplyColumn = ({ item }: { item: ReportPerAgentDomain }) => {
  return (
    <Text>
      {item.avgTimeToReplyInSeconds === null
        ? "N/A"
        : convertSecondsToHoursMinutesSeconds(item.avgTimeToReplyInSeconds)}
    </Text>
  );
};

interface ListProps {
  filteredReports: ReportPerAgentDomain[];
  setFilteredReports: React.Dispatch<
    React.SetStateAction<ReportPerAgentDomain[]>
  >;
  reports: ReportPerAgentDomain[];
  isLoading: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const List: React.FC<ListProps> = ({
  reports,
  filteredReports,
  setFilteredReports,
  isLoading,
  scrollContainerRef,
}) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [currentNameSorting, setCurrentNameSorting] = useState<
    "asc" | "desc" | undefined
  >(undefined);
  const [currentConversationsSorting, setCurrentConversationsSorting] =
    useState<"asc" | "desc" | undefined>(undefined);
  const [currentMessagesSorting, setCurrentMessagesSorting] = useState<
    "asc" | "desc" | undefined
  >(undefined);
  const [currentReplySorting, setCurrentReplySorting] = useState<
    "asc" | "desc" | undefined
  >(undefined);

  const getSortedAgentsReports = (reportsToSort: ReportPerAgentDomain[]) => {
    if (
      !currentNameSorting &&
      !currentConversationsSorting &&
      !currentMessagesSorting &&
      !currentReplySorting
    ) {
      return sortObjects(reportsToSort, [
        "uniqueConversations",
        "messagesSent",
        "avgTimeToReplyInSeconds",
      ]);
    }

    if (currentConversationsSorting) {
      return sortObjects(
        reportsToSort,
        ["uniqueConversations"],
        currentConversationsSorting
      );
    }

    if (currentMessagesSorting) {
      return sortObjects(
        reportsToSort,
        ["messagesSent"],
        currentMessagesSorting
      );
    }

    if (currentReplySorting) {
      return sortObjects(
        reportsToSort,
        ["avgTimeToReplyInSeconds"],
        currentReplySorting
      );
    }

    if (currentNameSorting) {
      const sortedAgents = [...agents]
        .sort((a, b) => {
          if (currentNameSorting === "asc") {
            return a.getFullName().localeCompare(b.getFullName());
          }

          return b.getFullName().localeCompare(a.getFullName());
        })
        .filter((a) => reportsToSort.some((r) => r.agentId === a.id));

      const sortedAgentReports: ReportPerAgentDomain[] = [];

      sortedAgents.forEach((a) => {
        sortedAgentReports.push(reportsToSort.find((r) => r.agentId === a.id)!);
      });

      return sortedAgentReports;
    }

    return reports;
  };

  useEffect(() => {
    if (selectedAgents && selectedAgents.length > 0) {
      setFilteredReports(
        getSortedAgentsReports(
          reports.filter((r) =>
            selectedAgents.some((a) => parseInt(a.value, 10) === r.agentId)
          )
        )
      );
    } else {
      setFilteredReports(getSortedAgentsReports(reports));
    }
  }, [reports, agents]);

  useEffect(() => {
    if (selectedAgents && selectedAgents.length > 0) {
      setFilteredReports(
        getSortedAgentsReports(
          filteredReports.filter((r) =>
            selectedAgents.some((a) => parseInt(a.value, 10) === r.agentId)
          )
        )
      );
    } else {
      setFilteredReports(getSortedAgentsReports(filteredReports));
    }
  }, [
    currentNameSorting,
    currentConversationsSorting,
    currentMessagesSorting,
    currentReplySorting,
  ]);

  const [agentOptions, setAgentOptions] = useState<AvailableAgentOption[]>(
    agents.map((a: AgentDomain) => ({
      value: a.id.toString(),
      label: a.getFullName(),
      avatarUrl: a.getPicture() || undefined,
    }))
  );

  useEffect(() => {
    setAgentOptions(
      agents.map((a: AgentDomain) => ({
        value: a.id.toString(),
        label: a.getFullName(),
        avatarUrl: a.getPicture() || undefined,
      }))
    );
  }, [agents]);

  const [selectedAgents, setSelectedAgents] = useState<
    MultiValue<AvailableAgentOption>
  >([]);

  const handleAgentsChange = (
    newSelectedAgents: MultiValue<AvailableAgentOption>
  ) => {
    setSelectedAgents(newSelectedAgents);
    setFilteredReports(
      sortObjects(
        newSelectedAgents && newSelectedAgents.length > 0
          ? reports.filter((r) =>
              newSelectedAgents.some((a) => parseInt(a.value, 10) === r.agentId)
            )
          : reports,
        ["uniqueConversations", "messagesSent", "avgTimeToReplyInSeconds"]
      )
    );
  };

  const memoizedColumns = useMemo(() => {
    return [
      {
        label: "Name",
        component: AgentNameColumn,
        currentSorting: currentNameSorting,
        setCurrentSorting: (newSorting: "asc" | "desc" | undefined) => {
          setCurrentNameSorting(newSorting);
          setCurrentConversationsSorting(undefined);
          setCurrentMessagesSorting(undefined);
          setCurrentReplySorting(undefined);
        },
      },
      {
        label: isBaseSize ? "Conversations" : "Unique Conversations",
        component: UniqueConversationsColumn,
        currentSorting: currentConversationsSorting,
        setCurrentSorting: (newSorting: "asc" | "desc" | undefined) => {
          setCurrentConversationsSorting(newSorting);
          setCurrentNameSorting(undefined);
          setCurrentMessagesSorting(undefined);
          setCurrentReplySorting(undefined);
        },
      },
      {
        label: isBaseSize ? "Messages" : "Messages Sent",
        component: MessagesSentColumn,
        currentSorting: currentMessagesSorting,
        setCurrentSorting: (newSorting: "asc" | "desc" | undefined) => {
          setCurrentMessagesSorting(newSorting);
          setCurrentNameSorting(undefined);
          setCurrentConversationsSorting(undefined);
          setCurrentReplySorting(undefined);
        },
      },
      {
        label: isBaseSize ? "Reply" : "Average Time to Reply",
        component: AverageTimeToReplyColumn,
        currentSorting: currentReplySorting,
        setCurrentSorting: (newSorting: "asc" | "desc" | undefined) => {
          setCurrentReplySorting(newSorting);
          setCurrentNameSorting(undefined);
          setCurrentConversationsSorting(undefined);
          setCurrentMessagesSorting(undefined);
        },
      },
    ];
  }, [
    currentNameSorting,
    currentConversationsSorting,
    currentMessagesSorting,
    currentReplySorting,
    isBaseSize,
  ]);

  return isLoading ? (
    [...Array(5).keys()].map((i) => (
      <>
        {i === 0 ? (
          <Box
            mt={4}
            height="2.5rem"
            width="100%"
            py={2}
            px={4}
            bgColor={colorMode === "dark" ? "gray.700" : "gray.100"}
            fontWeight="bold"
            color={colorMode === "dark" ? "gray.300" : "gray.600"}
          >
            <SkeletonText noOfLines={1} skeletonHeight={4} width="8rem" />
          </Box>
        ) : (
          <Divider />
        )}
        <Flex
          key={`skeleton-payment-${i}`}
          padding="6"
          w="100%"
          h="fit-content"
          gridGap={4}
        >
          <SkeletonCircle size="10" flexShrink={0} flexGrow={0} />
          <SkeletonText
            noOfLines={2}
            spacing={4}
            skeletonHeight={2}
            flexShrink={1}
            flexGrow={1}
          />
        </Flex>
      </>
    ))
  ) : (
    <Box w="100%" h="100%" overflowY="auto" ref={scrollContainerRef}>
      <Flex w={isBaseSize ? "100%" : "auto"} px={4} pb={4} pt={2}>
        <Select
          isMulti={true}
          placeholder="Filter by Agents"
          onChange={handleAgentsChange}
          options={agentOptions}
          value={selectedAgents}
          styles={{
            ...{
              ...getReactSelectStyles(colorMode, colorScheme),
              container: (provided: any) => ({
                ...provided,
                width: isBaseSize ? "100%" : "auto",
              }),
            },
          }}
          components={{ Option: AgentOption }}
        />
      </Flex>
      {filteredReports.length === 0 ? (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Heading as="h1" fontWeight="bold" size="xl">
            No data found for this date range 😱
          </Heading>
        </Flex>
      ) : (
        <SmartList
          items={filteredReports}
          itemIdentifier="id"
          containerRef={scrollContainerRef}
          columns={memoizedColumns}
        />
      )}
    </Box>
  );
};

export default List;
