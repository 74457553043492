export enum AttributeType {
  STRING = "string",
  INTEGER = "integer",
  FLOAT = "float",
  BOOLEAN = "boolean",
  DATETIME = "datetime",
}

export const AttributeTypeDisplayNames: Record<
  AttributeType,
  { displayName: string; example: string }
> = {
  [AttributeType.STRING]: { displayName: "🔡 Text", example: "e.g. abcde" },
  [AttributeType.INTEGER]: {
    displayName: "#️⃣ Whole Number",
    example: "e.g. 42",
  },
  [AttributeType.FLOAT]: { displayName: "📏 Decimal", example: "e.g. 89.95" },
  [AttributeType.BOOLEAN]: { displayName: "👍👎 Yes/No", example: "e.g. Yes" },
  [AttributeType.DATETIME]: {
    displayName: "🗓️ Date & Time",
    example: "e.g. 31 Oct 2022, 12:03 AM",
  },
};

export default class AttributeDomain {
  id: string;

  name: string;

  type: AttributeType;

  group_id: string;

  created_at: Date;

  constructor(
    id: string,
    name: string,
    type: AttributeType,
    group_id: string,
    created_at: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.group_id = group_id;
    this.created_at = new Date(created_at);
  }
}
