import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Flex,
  Text,
  useDisclosure,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import AgentDomain from "entities/domain/agents/agent-domain";
import Gallery from "components/shared/Gallery";
import { ReactComponent as AudioIcon } from "assets/icons/volume-high.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video-box.svg";
import { ReactComponent as PDFFile } from "assets/icons/file-pdf-box.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
import { SUPPORTED_MEDIA_TYPES } from "components/shared/FilePreview";
import { useAppSelector } from "redux/hooks";
import MessageWrapper from "./MessageWrapper";
import ExpandableText from "./ExpandableText";

export default ({
  message,
  showTail,
}: {
  message: MessageDomain;
  showTail: boolean;
}) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );
  const [startIndex, setStartIndex] = useState<number>(0);
  const [brokenMedia, setBrokenMedia] = useState<string[]>([]);

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  return (
    <>
      <MessageWrapper
        showTail={showTail}
        message={message}
        activeConversation={activeConversation}
        agent={agent}
      >
        <>
          <Flex
            justifyContent="center"
            mx="auto"
            alignItems="center"
            position="relative"
            height="7rem"
            width="7rem"
            _hover={{
              cursor: "pointer",
              opacity: colorMode === "dark" ? 0.8 : 0.5,
            }}
            onClick={() => {
              onOpen();
            }}
          >
            {message.attachments
              .slice(0, 3)
              .filter((attachment) => !brokenMedia.includes(attachment.id))
              .map((attachment, index) => (
                <Box
                  key={attachment.id}
                  position="absolute"
                  transform={`rotate(${index * 10}deg)`}
                  left={index * 3}
                >
                  {attachment.type.includes("image") &&
                    SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                      <Image
                        src={attachment.url}
                        alt={`attachment-${attachment.id}`}
                        boxSize="100%"
                        objectFit="cover"
                        borderRadius="lg"
                        width="5rem"
                        height="5rem"
                        loading="lazy"
                        onError={() => {
                          setBrokenMedia([...brokenMedia, attachment.id]);
                        }}
                      />
                    )}
                  {attachment.type.includes("video") &&
                    SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                      <Icon
                        as={VideoIcon}
                        width="5rem"
                        height="5rem"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderColor="gray.50"
                        borderRadius="lg"
                        fill={
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`
                        }
                      />
                    )}
                  {attachment.type.includes("audio") &&
                    SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                      <Icon
                        as={AudioIcon}
                        width="5rem"
                        height="5rem"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderColor="gray.50"
                        borderRadius="lg"
                        fill={
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`
                        }
                      />
                    )}
                  {attachment.type.includes("pdf") &&
                    SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                      <Icon
                        as={PDFFile}
                        width="5rem"
                        height="5rem"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderColor="gray.50"
                        borderRadius="lg"
                        fill={
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`
                        }
                      />
                    )}
                  {!SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                    <Icon
                      as={File}
                      width="5rem"
                      height="5rem"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderColor="gray.50"
                      borderRadius="lg"
                      fill={
                        colorMode === "dark"
                          ? `${colorScheme}.200`
                          : `${colorScheme}.500`
                      }
                    />
                  )}
                </Box>
              ))}
            {message.attachments.length > 3 && (
              <Flex
                bgColor={colorMode === "dark" ? "gray.800" : "gray.50"}
                width="3rem"
                height="3rem"
                borderRadius="full"
                position="absolute"
                left={message.attachments.length * 3}
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color={colorMode === "dark" ? "gray.400" : "gray.400"}
                >{`+${message.attachments.length - 3}`}</Text>
              </Flex>
            )}
          </Flex>
          <Box mt={1} width="12rem">
            {activeConversation?.channel === ConversationChannel.WHATSAPP ? (
              <EditorUltra
                fontColor={message.getFontColor()}
                isEditable={false}
                isScrollable={false}
                maxHeight="150px"
                showMore={true}
                backgroundColor="inherit"
                highlightUnknownCustomFields={false}
                defaultText={{
                  value: message?.body,
                }}
                enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
                channels={[ConversationChannel.WHATSAPP]}
              />
            ) : (
              <ExpandableText
                message={message}
                shouldLinkify={message.hasURLs()}
              />
            )}
          </Box>
        </>
      </MessageWrapper>
      <Gallery
        isOpen={isOpen}
        onClose={onClose}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        media={message.attachments}
      />
    </>
  );
};
