import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import AgentDomain from "entities/domain/agents/agent-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";

import MessageWrapper from "./MessageWrapper";
import ExpandableText from "./ExpandableText";

interface TextMessageProps {
  message: MessageDomain;
  showTail: boolean;
}

const TextMessage: React.FC<TextMessageProps> = ({ message, showTail }) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);

  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  return (
    <MessageWrapper
      showTail={showTail}
      message={message}
      agent={agent}
      activeConversation={activeConversation}
    >
      {activeConversation?.channel === ConversationChannel.WHATSAPP ? (
        <EditorUltra
          fontColor={message.getFontColor()}
          isEditable={false}
          isScrollable={false}
          maxHeight="150px"
          showMore={true}
          backgroundColor="inherit"
          highlightUnknownCustomFields={false}
          defaultText={{
            value: message?.body,
          }}
          channels={[ConversationChannel.WHATSAPP]}
          enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
        />
      ) : (
        <ExpandableText message={message} shouldLinkify={message.hasURLs()} />
      )}
    </MessageWrapper>
  );
};

export default TextMessage;
