import { Auth0ContextInterface } from "@auth0/auth0-react";
import LeadCountBySourceDomain from "entities/domain/leads/lead-count-by-source-domain";
import { request } from "util/methods";
import { LeadsCountBySourceDto } from "entities/dto/LeadCountBySourceDTO";
import { leadCountBySourceDtoToDomain } from "entities/transformers/leadTransformer";
import { RequestType } from "./request-type";

class LeadsService {
  public static async getLeadsCountBySource(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<LeadCountBySourceDomain[]> {
    const accessToken = await getAccessTokenSilently();
    const queryParams = new URLSearchParams();

    queryParams.append("date_from", dateFrom);
    queryParams.append("date_to", dateTo);

    const queryString = queryParams.toString();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/analytics/leads?${queryString}`
    );

    const leads_count_by_source: LeadsCountBySourceDto = data.data;
    return leadCountBySourceDtoToDomain(leads_count_by_source);
  }
}

export default LeadsService;
