import { StarIcon } from "@chakra-ui/icons";
import {
  HStack,
  Heading,
  Icon,
  IconButton,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React, { memo } from "react";
import { humanizeSnakeCase } from "util/methods";

interface TemplateItemProps {
  id: string;
  title: string;
  text: string;
  isFavourite: boolean;
  onClick: (templateId: string) => void;
  onMakeFavourite: (templateId: string) => void;
  onMakeGeneral: (templateId: string) => void;
}

const TemplateItem = ({
  id,
  title,
  text,
  isFavourite,
  onClick,
  onMakeFavourite,
  onMakeGeneral,
}: TemplateItemProps) => {
  const { colorMode } = useColorMode();

  return (
    <HStack
      w="100%"
      spacing={2}
      px={isFavourite === undefined ? 8 : 2}
      py={4}
      overflow="hidden"
      _hover={{
        background: colorMode === "dark" ? "gray.900" : "gray.50",
      }}
      onClick={() => {
        onClick(id);
      }}
    >
      <IconButton
        aria-label={`Mark as ${isFavourite ? "general" : "favourite"}`}
        icon={
          <Icon
            as={StarIcon}
            boxSize={4}
            color={
              colorMode === "dark"
                ? isFavourite
                  ? "yellow.200"
                  : "gray.200"
                : isFavourite
                ? "yellow.500"
                : "gray.500"
            }
          />
        }
        colorScheme={isFavourite ? "yellow" : "gray"}
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (isFavourite) {
            onMakeGeneral(id);
          } else {
            onMakeFavourite(id);
          }
        }}
      />
      <VStack justifyContent="start" alignItems="start" w="100%" pr={10}>
        <Heading
          as="h4"
          size="sm"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          textAlign="left"
        >
          {humanizeSnakeCase(title)}
        </Heading>
        <Text
          fontSize="sm"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={colorMode === "dark" ? "gray.500" : "gray.400"}
          textAlign="left"
        >
          {text}
        </Text>
      </VStack>
    </HStack>
  );
};

export default memo(TemplateItem);
