import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import { ReactComponent as CustomerOpportunityIcon } from "assets/icons/opportunity-contact-role-svgrepo-com.svg";
import React, { ReactNode, memo } from "react";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
  DarkMode,
  SystemStyleObject,
  HStack,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import DisplayStyledMessage from "./DisplayStyledMessage";
import MessageButtonsPreview from "./MessageButtonsPreview";

interface BorderStyles {
  borderRadius: string;
  borderWidth: string;
  borderStyle: string;
  borderColor: string;
}

const getMessageStyles = (
  isReceiving: boolean,
  colorMode: "light" | "dark",
  colorScheme: string
) => {
  const messageStyles: {
    border: BorderStyles | null;
    background: string;
    mr: string | number;
    ml: string | number;
  } = {
    border: {
      borderRadius: "xl",
      borderWidth: "0",
      borderStyle: "none",
      borderColor: "transparent",
    },
    background: colorMode === "dark" ? "gray.700" : "gray.50",
    mr: "auto",
    ml: "auto",
  };

  messageStyles.ml = isReceiving ? 8 : 0;
  messageStyles.mr = isReceiving ? 0 : 8;

  if (isReceiving) {
    if (colorMode === "light") {
      messageStyles.background = "white";
    } else {
      messageStyles.background = "gray.600";
    }

    return messageStyles;
  }

  if (colorMode === "light") {
    messageStyles.background = `${colorScheme}.500`;
  } else {
    messageStyles.background = `${colorScheme}.400`;
  }

  return messageStyles;
};

const getTailStyles = (
  isIncoming: boolean,
  messageBackgroundColor: string,
  showTail: boolean
): SystemStyleObject => {
  if (!showTail) {
    return {};
  }

  return isIncoming
    ? {
        _after: {
          content: "''",
          position: "absolute",
          bottom: 0,
          zIndex: 4,
          left: "-1.5rem",
          width: "2.25rem",
          height: "1.5rem",
          background: `radial-gradient(1.5rem at top left, #0000 99%, var(--chakra-colors-${messageBackgroundColor
            .split(".")
            .join("-")}) 102%)`,
        },
      }
    : {
        _after: {
          content: "''",
          position: "absolute",
          zIndex: 4,
          bottom: 0,
          right: "-1.5rem",
          width: "2.25rem",
          height: "1.5rem",
          background: `radial-gradient(1.5rem at top right, #0000 99%, var(--chakra-colors-${messageBackgroundColor
            .split(".")
            .join("-")}) 102%)`,
        },
      };
};

export default memo(
  ({
    children,
    message,
    showTail,
    activeConversation,
    agent,
    isOpen,
    setIsOpen,
    modalContent,
  }: React.PropsWithChildren<{
    isOpen?: boolean;
    setIsOpen?: (open: boolean) => void;
    message: MessageDomain;
    activeConversation: ConversationDomain | undefined;
    agent: AgentDomain | undefined;
    modalContent?: ReactNode;
    showTail: boolean;
  }>) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const { colorMode } = useColorMode();
    const { colorScheme } = useAppSelector((state) => state.theme);

    const {
      background: messageBackgroundColor,
      border: borderStyles,
      ml,
      mr,
    } = getMessageStyles(message.isIncoming, colorMode, colorScheme);
    const maxWidth = isBaseSize ? "75%" : "45%";
    const minWidth = isBaseSize ? "35%" : "25%";

    return (
      <>
        <Flex
          background={messageBackgroundColor}
          {...(borderStyles || {})}
          maxWidth={maxWidth}
          minWidth={minWidth}
          position="relative"
          ml={ml}
          mr={mr}
          zIndex={5}
          __css={getTailStyles(
            message.isIncoming,
            messageBackgroundColor,
            showTail
          )}
        >
          {message.isCustomerLeadMessage() ? (
            <HStack spacing={0} px={3} pt={2}>
              <Icon
                as={CustomerOpportunityIcon}
                boxSize={10}
                fill={colorMode === "dark" ? "gray.500" : "gray.400"}
              />
              <Heading
                as="h3"
                size="md"
                color={colorMode === "dark" ? "gray.500" : "gray.400"}
              >
                Message left on marketplace ✍️
              </Heading>
            </HStack>
          ) : null}
          <Flex w="100%" py={2} px={3} wordBreak="break-word">
            <DisplayStyledMessage
              message={message}
              agent={agent}
              activeConversation={activeConversation}
            >
              {children}
            </DisplayStyledMessage>
          </Flex>
          {message.buttons ? (
            <>
              {message.isIncoming ? (
                <MessageButtonsPreview buttons={message.buttons} />
              ) : (
                <DarkMode>
                  <MessageButtonsPreview
                    buttons={message.buttons}
                    forceColorMode={message.isIncoming ? "light" : "dark"}
                  />
                </DarkMode>
              )}
            </>
          ) : null}
        </Flex>
        <Modal
          isOpen={isOpen || false}
          onClose={() => {
            if (typeof setIsOpen !== "undefined") {
              setIsOpen(false);
            }
          }}
          size={isBaseSize ? "full" : "md"}
        >
          <ModalOverlay />
          <ModalContent
            {...(isBaseSize
              ? { alignItems: "center", justifyContent: "center" }
              : {})}
          >
            <ModalCloseButton />
            {modalContent}
          </ModalContent>
        </Modal>
      </>
    );
  }
);
