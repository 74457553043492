import MessageDomain, {
  MessageButton,
  MessageButtonType,
  MessageDirection,
  MessageStatus,
  MessageSubType,
  MessageType,
} from "entities/domain/conversations/message-domain";
import { MessageButtonDTO, MessageDTO } from "entities/dto/ConversationDTO";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import { flattenObject } from "util/methods";
import { parseISO } from "date-fns";
import { attachmentTransformFromDtoToDomain } from "./attachmentTransformer";

export const messageButtonTransformFromDtoToDomain = (
  b: MessageButtonDTO
): any => {
  return new MessageButton(
    b.type as MessageButtonType,
    b.text,
    b.url,
    b.phone_number
  );
};

export const messageTransformFromDtoToDomain = (m: MessageDTO): MessageDomain =>
  new MessageDomain(
    m.id,
    m.body,
    m.is_incoming,
    m.is_read,
    parseISO(m.created_at.includes("Z") ? m.created_at : `${m.created_at}Z`),
    m.conversation_id,
    m.agent_id,
    m.status ? (m.status as MessageStatus) : MessageStatus.UNKNOWN,
    m.type ? (m.type as MessageType) : MessageType.TEXT,
    m.sub_type as MessageSubType,
    m.title ? m.title : null,
    m.reply_to_message_id ? m.reply_to_message_id : null,
    m.direction as MessageDirection,
    m.metadata
      ? {
          ...m.metadata,
          attributes: m.metadata.attributes
            ? flattenObject(m.metadata.attributes)
            : undefined,
        }
      : null,
    m.attachments
      ? m.attachments.map((ato) => attachmentTransformFromDtoToDomain(ato))
      : ([] as AttachmentDomain[]),
    m.raw_html ? m.raw_html : null,
    m.buttons?.map(messageButtonTransformFromDtoToDomain) || null
  );

export const messagesTransformFromDtoToDomain = (
  messages: MessageDTO[]
): MessageDomain[] => messages.map(messageTransformFromDtoToDomain);

export default messagesTransformFromDtoToDomain;
