import LeadCountBySourceDomain from "entities/domain/leads/lead-count-by-source-domain";
import LeadEmailHandlerDomain from "entities/domain/leads/lead-email-handler-domain";
import { LeadsCountBySourceDto } from "entities/dto/LeadCountBySourceDTO";
import { LeadEmailHandlerDto } from "entities/dto/LeadEmailHandlerDTO";

export function leadEmailHandlerDtoToDomain(
  p: LeadEmailHandlerDto
): LeadEmailHandlerDomain {
  return new LeadEmailHandlerDomain(
    p.id,
    p.email,
    p.source,
    p.merchant_id,
    p.created_at
  );
}

export function leadCountBySourceDtoToDomain(
  p: LeadsCountBySourceDto
): LeadCountBySourceDomain[] {
  return p.leads_count_by_source.map(
    (sourceCount) =>
      new LeadCountBySourceDomain(
        p.merchant_id,
        sourceCount.source,
        p.date_from || null,
        p.date_to || null,
        sourceCount.count
      )
  );
}
