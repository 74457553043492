import React from "react";
import { Box, Flex, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import MessageDomain from "entities/domain/conversations/message-domain";
import { useAppSelector } from "redux/hooks";
import ExpandableText from "./ExpandableText";
import PhoneIcon from "./PhoneIcon";

export default ({ message }: { message: MessageDomain }) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { currentAgent } = useAppSelector((state) => state.agents);

  const currentAgentBackground =
    currentAgent!.getChatBackgroundStyles(colorMode);
  let shouldGuaranteeReadability = false;

  if (
    currentAgentBackground &&
    currentAgentBackground.background &&
    typeof currentAgentBackground.background === "string" &&
    !currentAgentBackground.background.includes("gray")
  ) {
    shouldGuaranteeReadability = true;
  }

  return (
    <Flex
      flexDirection="column"
      w={isBaseSize ? "80%" : "30%"}
      position="relative"
      alignItems="center"
      borderRadius="xl"
      mx="auto"
      my={2}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        {...(shouldGuaranteeReadability
          ? {
              mb: "-1rem",
              zIndex: 1,
              p: 2,
              borderRadius: "full",
              backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
            }
          : {})}
      >
        <PhoneIcon message={message} colorMode={colorMode} />
      </Flex>
      <Box
        fontSize="xs"
        p={4}
        {...(shouldGuaranteeReadability
          ? {
              mb: -2,
              borderRadius: "xl",
              backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
              width: "fit-content",
            }
          : {})}
      >
        <ExpandableText message={message} shouldLinkify={message.hasURLs()} />
      </Box>
    </Flex>
  );
};
