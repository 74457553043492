import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Divider,
  Flex,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import LeadAnalyticsPage from "components/analytics/leads/LeadsAnalytics";
import { addDays, endOfDay, format, startOfDay, subDays } from "date-fns";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { convertSecondsToHoursMinutesSeconds } from "util/methods";
import { canViewAgentsAnalytics } from "util/permissions";
import AnalyticsTopBar from "./AnalyticsTopBar";
import BarChart from "./BarChart";
import DateFilter from "./DateFilter";
import DesktopAnalyticsTabs from "./DesktopAnalyticsTabs";
import List from "./List";
import MobileAnalyticsTabs from "./MobileAnalyticsTabs";

interface AnalyticsPageProps {}

const AnalyticsPage = (_props: AnalyticsPageProps) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent, agents } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const toast = useToast();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [fromDate, setFromDate] = useState<Date | null>(
    startOfDay(subDays(new Date(), 7))
  );
  const [toDate, setToDate] = useState<Date | null>(endOfDay(new Date()));

  const [reports, setReports] = useState<ReportPerAgentDomain[]>([]);
  const [filteredReports, setFilteredReports] = useState<
    ReportPerAgentDomain[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showBarChart, setShowBarChart] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const loadReports = async () => {
    setIsLoading(true);

    try {
      const newReports = await AgentsService.getAnalyticsReports(
        auth0Context,
        merchant.id,
        format(fromDate!, "yyyy-MM-dd"),
        format(toDate!, "yyyy-MM-dd")
      );

      setReports(newReports);
    } catch (error) {
      toast({
        status: "error",
        title: "We were unable to retrieve analytics data at this time",
        description: "Please try again or contact us if the issue persists.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!canViewAgentsAnalytics(merchant.id, currentAgent!)) {
      setIsLoading(false);
      return;
    }

    loadReports();
  }, [toDate, fromDate, merchant.id]);

  useEffect(() => {
    if (canViewAgentsAnalytics(merchant.id, currentAgent!)) {
      return;
    }

    navigate(`/${merchant.id}`);
  }, [merchant.id]);

  const mappedReports = useMemo(
    () =>
      reports
        .filter((r) => agents.map((a) => a.id).includes(r.agentId))
        .map((r) => {
          return {
            name: agents.find((agent) => agent.id === r.agentId)!.getFullName(),
            uniqueConversations: r.uniqueConversations,
            messagesSent: r.messagesSent,
            avgTimeToReplyInSeconds: r.avgTimeToReplyInSeconds
              ? convertSecondsToHoursMinutesSeconds(r.avgTimeToReplyInSeconds)
              : null,
          };
        }),
    [reports, agents]
  );

  return (
    <Flex
      direction="column"
      position="relative"
      flex={1}
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
          })}
    >
      <AnalyticsTopBar
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        mappedReports={mappedReports}
        isLoading={isLoading}
        tabIndex={tabIndex}
      />
      <MobileAnalyticsTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <Flex
        direction="row"
        overflow="hidden"
        height="100%"
        width="100%"
        minWidth="100%"
        alignItems="start"
      >
        <DesktopAnalyticsTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
        <VStack
          spacing={0}
          divider={<Divider />}
          {...(isBaseSize
            ? {}
            : {
                borderTopRadius: "3xl",
              })}
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          w="100%"
          h="100%"
          overflowY="hidden"
          mr={isBaseSize ? 0 : 4}
        >
          {tabIndex === 0 ? (
            <>
              <Flex
                gridGap={2}
                justifyContent="start"
                alignItems="center"
                w="100%"
                p={4}
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor={
                  colorMode === "dark" ? "gray.700" : "gray.200"
                }
              >
                <Button
                  isActive={showBarChart}
                  variant="ghost"
                  colorScheme={colorScheme}
                  onClick={() => {
                    setShowBarChart(true);
                  }}
                >
                  Chart 📊
                </Button>
                <Button
                  isActive={!showBarChart}
                  colorScheme={colorScheme}
                  variant="ghost"
                  onClick={() => {
                    setShowBarChart(false);
                  }}
                >
                  Table 📋
                </Button>
              </Flex>
              {isBaseSize ? (
                <DateFilter
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              ) : null}
              {showBarChart ? (
                <BarChart isLoading={isLoading} reports={reports} />
              ) : (
                <List
                  reports={reports}
                  filteredReports={filteredReports}
                  setFilteredReports={setFilteredReports}
                  isLoading={isLoading}
                  scrollContainerRef={scrollContainerRef}
                />
              )}
            </>
          ) : (
            <Box w="100%" h="100%">
              {isBaseSize ? null : (
                <Flex
                  gridGap={2}
                  justifyContent="start"
                  alignItems="center"
                  w="100%"
                  p={4}
                  borderBottomWidth="1px"
                  borderBottomStyle="solid"
                  borderBottomColor={
                    colorMode === "dark" ? "gray.700" : "gray.200"
                  }
                >
                  <Button
                    colorScheme={colorScheme}
                    variant="ghost"
                    isActive={true}
                  >
                    Table 📋
                  </Button>
                </Flex>
              )}
              <LeadAnalyticsPage
                fromDate={fromDate}
                toDate={toDate}
                scrollContainerRef={scrollContainerRef}
              />
            </Box>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};

export default AnalyticsPage;
