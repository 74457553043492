import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MessageDomain from "entities/domain/conversations/message-domain";
import { RootState } from "redux/store";
import { replaceOrAppendMessagesInArray, sortDates } from "util/conversations";

interface MessagesState {
  loading: boolean;
  errors: string[];
  messagesLastPageLengths: { [key: number]: number };
  messages: { [key: number]: MessageDomain[] };
  isLoadingMessages: boolean;
  autoReplySuggestion: string | undefined;
  emailMessageToShow: MessageDomain | undefined;
}

const initialState: MessagesState = {
  loading: true,
  errors: [],
  messagesLastPageLengths: {},
  messages: {},
  isLoadingMessages: true,
  autoReplySuggestion: undefined,
  emailMessageToShow: undefined,
};

const getMessagesWithUpdatedReadStatus = (
  offsetMessageId: number,
  isRead: boolean,
  messages: MessageDomain[]
): MessageDomain[] => {
  let offsetMessagePassed = false;

  // We expect messages to be sorted
  return messages.map((m, i) => {
    if (m.id === offsetMessageId) {
      offsetMessagePassed = true;
    }

    const shouldUpdate =
      (isRead && (!offsetMessagePassed || m.id === offsetMessageId)) ||
      (!isRead && offsetMessagePassed);

    return shouldUpdate
      ? (Object.setPrototypeOf(
          {
            ...m,
            isRead,
          },
          MessageDomain.prototype
        ) as MessageDomain)
      : m;
  });
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setIsLoadingMessagesAction(state, action: PayloadAction<boolean>) {
      state.isLoadingMessages = action.payload;
    },
    setAutoReplySuggestion(state, action: PayloadAction<string | undefined>) {
      state.autoReplySuggestion = action.payload;
    },
    setMessagesReadStatusAction(
      state,
      action: PayloadAction<{
        conversationId: number;
        offsetMessageId: number;
        isRead: boolean;
      }>
    ) {
      if (!state.messages[action.payload.conversationId]) {
        return;
      }

      state.messages[action.payload.conversationId] =
        getMessagesWithUpdatedReadStatus(
          action.payload.offsetMessageId,
          action.payload.isRead,
          state.messages[action.payload.conversationId]
        );
    },
    deleteMessage(
      state,
      action: PayloadAction<{
        conversationId: number;
        messageId: number;
      }>
    ) {
      if (!state.messages[action.payload.conversationId]) {
        return;
      }

      state.messages[action.payload.conversationId] = state.messages[
        action.payload.conversationId
      ].filter((m) => m.id !== action.payload.messageId);
    },
    clearMessages(
      state,
      action: PayloadAction<{
        conversationId: number | undefined;
      }>
    ) {
      if (
        !action.payload.conversationId ||
        Object.keys(state.messages).length > 20
      ) {
        state.messages = {};
        state.messagesLastPageLengths = {};
        return;
      }

      Object.keys(state.messages).forEach((key) => {
        const cid = parseInt(key, 10);
        if (cid !== action.payload.conversationId) {
          delete state.messages[cid];
          delete state.messagesLastPageLengths[cid];
        }
      });
    },
    openEmailMessage(state, action: PayloadAction<MessageDomain | undefined>) {
      state.emailMessageToShow = action.payload;
    },
    closeEmailMessage(state) {
      state.emailMessageToShow = undefined;
    },
    appendMessage(
      state,
      action: PayloadAction<{
        conversationId: number;
        message: MessageDomain;
      }>
    ) {
      state.messages[action.payload.conversationId] =
        replaceOrAppendMessagesInArray(
          [action.payload.message],
          state.messages[action.payload.conversationId] || []
        ).sort((c1, c2) => sortDates(c2.createdAt, c1.createdAt));
    },
    appendMessages(
      state,
      action: PayloadAction<{
        conversationId: number;
        list: MessageDomain[];
      }>
    ) {
      state.messagesLastPageLengths[action.payload.conversationId] =
        action.payload.list.length;

      state.messages[action.payload.conversationId] =
        replaceOrAppendMessagesInArray(
          action.payload.list,
          state.messages[action.payload.conversationId] || []
        ).sort((c1, c2) => sortDates(c2.createdAt, c1.createdAt));
    },
    resetStore(state) {
      state.loading = true;
      state.errors = [];
      state.messagesLastPageLengths = {};
      state.messages = {};
      state.isLoadingMessages = true;
      state.autoReplySuggestion = undefined;
      state.emailMessageToShow = undefined;
    },
  },
});

export const autoReplySuggestionSelector = (state: RootState) =>
  state.messages.autoReplySuggestion;

export const messagesSelector = (state: RootState): MessageDomain[] => {
  if (
    typeof state.conversations.activeConversationId === "undefined" ||
    !state.messages.messages[state.conversations.activeConversationId]
  ) {
    return [];
  }

  return state.messages.messages[state.conversations.activeConversationId];
};

export const messagesLastPagesSelector = (state: RootState): number | null => {
  if (
    typeof state.conversations.activeConversationId === "undefined" ||
    typeof state.messages.messagesLastPageLengths[
      state.conversations.activeConversationId
    ] === "undefined"
  ) {
    return null;
  }

  return state.messages.messagesLastPageLengths[
    state.conversations.activeConversationId
  ];
};

export const allMessagesSelector = (
  state: RootState
): { [key: number]: MessageDomain[] } => {
  return state.messages.messages;
};

export const isLoadingMessagesSelector = (state: RootState) =>
  state.messages.isLoadingMessages;

export const {
  openEmailMessage,
  closeEmailMessage,
  setMessagesReadStatusAction,
  appendMessage,
  appendMessages,
  setIsLoadingMessagesAction,
  setAutoReplySuggestion,
  deleteMessage,
  clearMessages,
  resetStore,
} = messagesSlice.actions;

export default messagesSlice.reducer;
