import { useAuth0 } from "@auth0/auth0-react";
import { SettingsRoutes } from "components/user-settings";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import FullTemplateDomain from "entities/domain/templates/full_template";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import { useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  createTemplate,
  createTemplateFail,
  CreateTemplatePayload,
  createTemplateSuccess,
  deleteTemplate,
  deleteTemplateFail,
  DeleteTemplatePayload,
  deleteTemplateSuccess,
  editTemplate,
  editTemplateFail,
  EditTemplatePayload,
  editTemplateSuccess,
  fetchTemplates,
  fetchTemplatesFail,
  fetchTemplatesSuccess,
  propagateTemplateUpdate,
  SyncTemplatePayload,
  TemplateFilter,
} from "redux/features/templates";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import TemplatesService from "services/templates";

export default function useTemplatesStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const location = useLocation();
  const getTemplatesLastRequest = useRef<object>({});
  const { activeTemplateFilter } = useAppSelector((state) => state.templates);

  const fetchTemplatesWaterfall =
    (searchText?: string, isRegular?: boolean) =>
    async (): Promise<
      FullTemplateDomain[] | WhatsappTemplate[] | undefined
    > => {
      try {
        dispatch(fetchTemplates());
        getTemplatesLastRequest.current = {};
        const currentRequest = getTemplatesLastRequest.current;

        if (
          (typeof isRegular !== "undefined" && isRegular) ||
          (location.pathname
            .split("/")
            .filter((p) => !!p)
            .reverse()[0] === SettingsRoutes.TEMPLATES &&
            activeTemplateFilter !== TemplateFilter.WHATSAPP)
        ) {
          const templatesResponse = await TemplatesService.getTemplates(
            auth0Context,
            merchant,
            currentAgent!,
            searchText
          );
          const newTemplates = templatesResponse.filter(
            (t) =>
              !(
                t.channels.includes(ConversationChannel.WHATSAPP) &&
                t.channels.length === 1
              )
          );

          if (currentRequest !== getTemplatesLastRequest.current) {
            return;
          }

          dispatch(fetchTemplatesSuccess(newTemplates));
          return newTemplates;
        } else {
          const templatesResponse = await TemplatesService.getWhatsappTemplates(
            auth0Context,
            merchant,
            searchText
          );
          if (currentRequest !== getTemplatesLastRequest.current) {
            return;
          }
          dispatch(fetchTemplatesSuccess(templatesResponse));
          return templatesResponse;
        }
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't load templates. Please try again!"
        );
        dispatch(fetchTemplatesFail([errorMessage]));
        return undefined;
      }
    };

  const createTemplateWaterfall =
    (payload: CreateTemplatePayload) =>
    async (): Promise<FullTemplateDomain | undefined> => {
      try {
        dispatch(createTemplate());

        const templateResponse = await TemplatesService.createTemplate(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(createTemplateSuccess());
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this template. Please try again!"
        );
        dispatch(createTemplateFail([errorMessage]));
        return undefined;
      }
    };

  const editTemplateWaterfall =
    (payload: EditTemplatePayload) =>
    async (): Promise<FullTemplateDomain | undefined> => {
      try {
        dispatch(editTemplate());

        const templateResponse = await TemplatesService.editTemplate(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(editTemplateSuccess());
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this template. Please try again!"
        );
        dispatch(editTemplateFail([errorMessage]));
        return undefined;
      }
    };

  const syncTemplateWaterfall =
    (payload: SyncTemplatePayload) =>
    async (): Promise<FullTemplateDomain | undefined> => {
      try {
        dispatch(editTemplate());

        const templateResponse = await TemplatesService.syncTemplate(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(editTemplateSuccess());
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't sync this template. Please try again!"
        );
        dispatch(editTemplateFail([errorMessage]));
        return undefined;
      }
    };

  const syncWhatsappTemplateWaterfall =
    (payload: SyncTemplatePayload) =>
    async (): Promise<WhatsappTemplate | undefined> => {
      try {
        dispatch(editTemplate());

        const templateResponse = await TemplatesService.syncWhatsappTemplate(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(propagateTemplateUpdate(templateResponse));
        dispatch(editTemplateSuccess());
        return templateResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't sync this template. Please try again!"
        );
        dispatch(editTemplateFail([errorMessage]));
        return undefined;
      }
    };

  const deleteTemplateWaterfall =
    (payload: DeleteTemplatePayload) => async () => {
      try {
        dispatch(deleteTemplate());

        const templateResponse = await TemplatesService.deleteTemplate(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(deleteTemplateSuccess(templateResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this template. Please try again!"
        );
        dispatch(deleteTemplateFail([errorMessage]));
      }
    };

  const fetchTemplatesAsync = useCallback(
    (searchText?: string, isRegular?: boolean) =>
      fetchTemplatesWaterfall(searchText, isRegular)(),
    [dispatch, merchant, activeTemplateFilter, location.pathname]
  );

  const createTemplateAsync = useCallback(
    (payload: CreateTemplatePayload) => createTemplateWaterfall(payload)(),
    [dispatch, merchant]
  );

  const editTemplateAsync = useCallback(
    (payload: EditTemplatePayload) => editTemplateWaterfall(payload)(),
    [dispatch, merchant]
  );

  const deleteTemplateAsync = useCallback(
    (payload: DeleteTemplatePayload) => deleteTemplateWaterfall(payload)(),
    [dispatch, merchant]
  );

  const syncTemplateAsync = useCallback(
    (payload: SyncTemplatePayload) => syncTemplateWaterfall(payload)(),
    [dispatch, merchant]
  );

  const syncWhatsappTemplateAsync = useCallback(
    (payload: SyncTemplatePayload) => syncWhatsappTemplateWaterfall(payload)(),
    [dispatch, merchant]
  );

  const markTemplateAsFavouriteOrGeneral = useCallback(
    (template: TemplateDomain | undefined, markFavourite: boolean) => {
      if (!template) return;

      return editTemplateAsync({
        id: template.id,
        title: template.title,
        text: template.text,
        channels: template.channels || [],
        favourite: markFavourite,
        shortcut: template.shortcut || null,
        subject: template.subject || null,
        category: template.category || null,
      });
    },
    [editTemplateAsync]
  );

  return {
    fetchTemplates: fetchTemplatesAsync,
    createTemplate: createTemplateAsync,
    editTemplate: editTemplateAsync,
    deleteTemplate: deleteTemplateAsync,
    syncTemplate: syncTemplateAsync,
    syncWhatsappTemplate: syncWhatsappTemplateAsync,
    markTemplateAsFavouriteOrGeneral,
  };
}
