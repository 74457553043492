import { Flex, Text } from "@chakra-ui/react";
import MessageDomain from "entities/domain/conversations/message-domain";
import React from "react";
import { useAppSelector } from "redux/hooks";
import MessageStatus from "./MessageStatus";

interface MessageFooterProps {
  message: MessageDomain;
}

const MessageFooter: React.FC<MessageFooterProps> = ({ message }) => {
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const textColor = message.isIncoming ? "gray.500" : `${colorScheme}.300`;

  return (
    <Flex direction="row-reverse" alignItems="center" gridGap={1}>
      <MessageStatus
        activeConversation={activeConversation}
        message={message}
      />
      {activeConversation &&
        message.getStatusIcon(activeConversation?.channel) !== "failed" && (
          <Text color={textColor} fontSize="xs">
            {message.getTimeStamp()}
          </Text>
        )}
    </Flex>
  );
};

export default MessageFooter;
