import { useAuth0 } from "@auth0/auth0-react";
import { PlusSquareIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as AllConversationsIcon } from "assets/icons/all-conversations.svg";
import { ReactComponent as PersonalConversationsIcon } from "assets/icons/personal-conversations.svg";
import { ReactComponent as UnassignedConversationsIcon } from "assets/icons/unassigned-conversations.svg";
import TeamDomain from "entities/domain/team";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import useConversationsStore from "hooks/use-conversations-store";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { setTeams } from "redux/features/agents";
import {
  ConversationTab,
  clearSelectedConversations,
  fetchConversations,
  setActiveConversationTab,
  setSearchText,
  setSelectedInbox,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { canManageTeamInbox } from "util/permissions";
import AddTeamModal from "../shared/AddTeamModal";
import Conversations from "./ConversationsArea";

const getActiveTabIndex = (
  tabName: ConversationTab | undefined
): number | undefined => {
  switch (tabName) {
    case ConversationTab.Personal:
      return 1;
    case ConversationTab.Team:
      return 0;
    case ConversationTab.Unassigned:
      return 2;
    default:
      return undefined;
  }
};

const getTabName = (index: number): ConversationTab => {
  switch (index) {
    case 0:
      return ConversationTab.Team;
    case 1:
      return ConversationTab.Personal;
    case 2:
      return ConversationTab.Unassigned;
    default:
      throw new Error(`Invalid tab (index: ${index}) has been chosen`);
  }
};

const getUnreadTotalForTeam = (
  teamId: string,
  unreadCount?: UnreadCountDTO
): number => {
  if (!unreadCount || !unreadCount.teams || !unreadCount.teams[teamId]) {
    return 0;
  }

  const unreadCountTotal =
    unreadCount.teams[teamId].personal +
    unreadCount.teams[teamId].shared +
    unreadCount.teams[teamId].unassigned;

  return unreadCountTotal;
};

interface DesktopTabsAreaProps {}

const DesktopTabsArea = (_props: DesktopTabsAreaProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { getConversations, getConversation } = useConversationsStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.conversations);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const {
    activeTab,
    searchText,
    filterAgents,
    filterChannels,
    activeConversationId: conversationId,
    filterCustomerTagIds,
    isOpenOrClosed,
    selectedInbox,
  } = useAppSelector((state) => state.conversations);
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { colorMode } = useColorMode();
  const { agents, currentAgent, teams } = useAppSelector(
    (state) => state.agents
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [showAddTeamModal, setShowAddTeamModal] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(""));
      dispatch(setSelectedInbox(null));
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;
    const teamInbox = queryParams.get("team") as string | null;

    if (!tab) {
      const isSingleAgent = agents.length === 1;
      const isManager = canManageTeamInbox(merchant.id, currentAgent!);
      const shouldShowAllInOneTab = isManager && isSingleAgent;

      if (shouldShowAllInOneTab) {
        dispatch(setActiveConversationTab(ConversationTab.Team));
      } else {
        dispatch(setActiveConversationTab(ConversationTab.Personal));
      }
    } else if (tab !== activeTab) {
      dispatch(setActiveConversationTab(tab || undefined));
    }

    if (teamInbox !== selectedInbox) {
      dispatch(setSelectedInbox(teamInbox));
    }
  }, [
    agents,
    currentAgent,
    merchant,
    currentSearchParameters,
    activeConversationId,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;

    if (activeTab && !tab) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        tab: activeTab,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const teamInbox = queryParams.get("team") as string | null;

    if (selectedInbox && !teamInbox) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        team: selectedInbox,
      });
    }
  }, [selectedInbox]);

  useEffect(() => {
    if (activeTab) {
      dispatch(clearSelectedConversations());
      getConversations(0).then((res) => {
        const filterResult = res?.filter((conv) => conv.id === conversationId);

        if (filterResult?.length === 0 && conversationId) {
          getConversation(conversationId, currentAgent!.id);
        }
      });
    }
  }, [
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTagIds,
    isOpenOrClosed,
    activeTab,
    selectedInbox,
  ]);

  useEffect(() => {
    AgentsService.getTeams(auth0Context, merchant.id)
      .then((allTeams) => {
        dispatch(setTeams(allTeams));
      })
      .catch(() => {});
  }, []);

  if (!activeTab) {
    return null;
  }

  const activeTabIndex = getActiveTabIndex(activeTab);

  if (agents.length > 1) {
    return (
      <Flex
        direction="row"
        overflow="hidden"
        height="100%"
        width="100%"
        minWidth="100%"
        alignItems="start"
      >
        <VStack
          width="12rem"
          minWidth="12rem"
          maxWidth="12rem"
          alignItems="start"
          height="100%"
          overflowY="auto"
        >
          <Accordion
            w="100%"
            border="none"
            allowToggle={true}
            allowMultiple={true}
            defaultIndex={[0, 1]}
          >
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    pl={2}
                    alignItems="center"
                    justifyContent="start"
                    _hover={{
                      bg: "inherit",
                    }}
                    gridGap={2}
                  >
                    {isExpanded ? (
                      <Icon
                        as={TriangleDownIcon}
                        width="0.5rem"
                        height="0.5rem"
                        color={colorMode === "light" ? "gray.600" : "gray.300"}
                      />
                    ) : (
                      <Icon
                        as={TriangleDownIcon}
                        transform="rotate(-90deg)"
                        width="0.5rem"
                        height="0.5rem"
                        color={colorMode === "light" ? "gray.600" : "gray.300"}
                      />
                    )}
                    <Text
                      color={colorMode === "light" ? "gray.600" : "gray.300"}
                      textAlign="left"
                      w="100%"
                      fontWeight="bold"
                    >
                      General
                    </Text>
                  </AccordionButton>
                  <AccordionPanel
                    p={0}
                    pr={2}
                    display="flex"
                    flexDirection="column"
                    gridGap={1}
                  >
                    {canManageTeamInbox(merchant.id, currentAgent!) ? (
                      <Button
                        pl={7}
                        colorScheme={colorScheme}
                        w="100%"
                        borderLeftRadius={0}
                        variant="ghost"
                        isActive={activeTabIndex === 0 && !selectedInbox}
                        justifyContent="start"
                        alignItems="center"
                        onClick={() => {
                          if (
                            !selectedInbox &&
                            activeTab === ConversationTab.Team
                          ) {
                            return;
                          }

                          currentSearchParameters.delete("team");
                          dispatch(fetchConversations());
                          setSearchParams({
                            ...Object.fromEntries(
                              currentSearchParameters.entries()
                            ),
                            tab: getTabName(0),
                          });
                        }}
                      >
                        <Icon
                          as={AllConversationsIcon}
                          mr={2}
                          width="1.35rem"
                          height="1.35rem"
                          __css={{
                            path: {
                              stroke:
                                activeTabIndex === 0 && !selectedInbox
                                  ? colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.600`
                                  : colorMode === "dark"
                                  ? "gray.200"
                                  : "gray.600",
                            },
                          }}
                        />
                        <Text
                          textAlign="left"
                          w="100%"
                          color={
                            activeTabIndex === 0 && !selectedInbox
                              ? colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.600`
                              : colorMode === "dark"
                              ? "gray.200"
                              : "gray.600"
                          }
                        >
                          All
                        </Text>
                        {unreadCount?.shared ? (
                          <Badge ml={1} colorScheme="red">
                            {unreadCount.shared > 100
                              ? "100+"
                              : unreadCount.shared}
                          </Badge>
                        ) : null}
                      </Button>
                    ) : null}
                    <Button
                      pl={7}
                      variant="ghost"
                      colorScheme={colorScheme}
                      borderLeftRadius={0}
                      justifyContent="start"
                      alignItems="center"
                      isActive={activeTabIndex === 1 && !selectedInbox}
                      w="100%"
                      onClick={() => {
                        if (
                          !selectedInbox &&
                          activeTab === ConversationTab.Personal
                        ) {
                          return;
                        }

                        currentSearchParameters.delete("team");
                        dispatch(fetchConversations());
                        setSearchParams({
                          ...Object.fromEntries(
                            currentSearchParameters.entries()
                          ),
                          tab: getTabName(1),
                        });
                      }}
                      color={
                        activeTabIndex === 1 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    >
                      <Icon
                        as={PersonalConversationsIcon}
                        mr={2}
                        width="1.5rem"
                        height="1.5rem"
                        __css={{
                          path: {
                            stroke:
                              activeTabIndex === 1 && !selectedInbox
                                ? colorMode === "dark"
                                  ? `${colorScheme}.200`
                                  : `${colorScheme}.600`
                                : colorMode === "dark"
                                ? "gray.200"
                                : "gray.600",
                          },
                        }}
                      />
                      <Text
                        textAlign="left"
                        w="100%"
                        color={
                          activeTabIndex === 1 && !selectedInbox
                            ? colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.600`
                            : colorMode === "dark"
                            ? "gray.200"
                            : "gray.600"
                        }
                      >
                        Personal
                      </Text>
                      {unreadCount?.personal ? (
                        <Badge ml={1} colorScheme="red">
                          {unreadCount.personal > 100
                            ? "100+"
                            : unreadCount.personal}
                        </Badge>
                      ) : null}
                    </Button>
                    <Button
                      pl={7}
                      variant="ghost"
                      colorScheme={colorScheme}
                      borderLeftRadius={0}
                      justifyContent="start"
                      alignItems="center"
                      isActive={activeTabIndex === 2 && !selectedInbox}
                      w="100%"
                      onClick={() => {
                        if (
                          !selectedInbox &&
                          activeTab === ConversationTab.Unassigned
                        ) {
                          return;
                        }

                        currentSearchParameters.delete("team");
                        dispatch(fetchConversations());
                        setSearchParams({
                          ...Object.fromEntries(
                            currentSearchParameters.entries()
                          ),
                          tab: getTabName(2),
                        });
                      }}
                      color={
                        activeTabIndex === 2 && !selectedInbox
                          ? colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.600`
                          : colorMode === "dark"
                          ? "gray.200"
                          : "gray.600"
                      }
                    >
                      <Icon
                        as={UnassignedConversationsIcon}
                        mr={2}
                        width="1.5rem"
                        height="1.5rem"
                        __css={{
                          path: {
                            stroke:
                              activeTabIndex === 2 && !selectedInbox
                                ? colorMode === "dark"
                                  ? `${colorScheme}.200`
                                  : `${colorScheme}.600`
                                : colorMode === "dark"
                                ? "gray.200"
                                : "gray.600",
                          },
                        }}
                      />
                      <Text
                        textAlign="left"
                        w="100%"
                        color={
                          activeTabIndex === 2 && !selectedInbox
                            ? colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.600`
                            : colorMode === "dark"
                            ? "gray.200"
                            : "gray.600"
                        }
                      >
                        Unassigned
                      </Text>
                      {unreadCount?.unassigned ? (
                        <Badge ml={1} colorScheme="red">
                          {unreadCount.unassigned > 100
                            ? "100+"
                            : unreadCount.unassigned}
                        </Badge>
                      ) : null}
                    </Button>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            {teams.length > 0 ? (
              <AccordionItem mt={0} border="none">
                {({ isExpanded: isTeamExpanded }) => (
                  <>
                    <AccordionButton
                      pl={2}
                      alignItems="center"
                      justifyContent="start"
                      _hover={{
                        bg: "inherit",
                      }}
                      gridGap={2}
                    >
                      {isTeamExpanded ? (
                        <Icon
                          as={TriangleDownIcon}
                          width="0.5rem"
                          height="0.5rem"
                          color={
                            colorMode === "light" ? "gray.600" : "gray.300"
                          }
                        />
                      ) : (
                        <Icon
                          as={TriangleDownIcon}
                          transform="rotate(-90deg)"
                          width="0.5rem"
                          height="0.5rem"
                          color={
                            colorMode === "light" ? "gray.600" : "gray.300"
                          }
                        />
                      )}
                      <Text
                        color={colorMode === "light" ? "gray.600" : "gray.300"}
                        textAlign="left"
                        w="100%"
                        fontWeight="bold"
                      >
                        Teams
                      </Text>
                    </AccordionButton>
                    <AccordionPanel p={0}>
                      <Accordion
                        border="none"
                        allowToggle={true}
                        allowMultiple={true}
                        w="100%"
                        defaultIndex={teams.reduce((acc, t, i) => {
                          if (t.id === selectedInbox) {
                            acc.push(i);
                          }

                          return acc;
                        }, [] as number[])}
                      >
                        {teams.map((t: TeamDomain) => (
                          <AccordionItem border="none" w="100%" key={t.id}>
                            {({ isExpanded }) => (
                              <>
                                <AccordionButton
                                  borderLeftRadius={0}
                                  justifyContent="start"
                                  alignItems="center"
                                  w="100%"
                                  color={
                                    selectedInbox === t.id
                                      ? colorMode === "dark"
                                        ? `${colorScheme}.200`
                                        : `${colorScheme}.600`
                                      : colorMode === "dark"
                                      ? "gray.200"
                                      : "gray.600"
                                  }
                                  gridGap={2}
                                >
                                  {isExpanded ? (
                                    <Icon
                                      as={TriangleDownIcon}
                                      width="0.5rem"
                                      height="0.5rem"
                                      color={
                                        selectedInbox === t.id
                                          ? colorMode === "dark"
                                            ? `${colorScheme}.200`
                                            : `${colorScheme}.600`
                                          : colorMode === "dark"
                                          ? "gray.200"
                                          : "gray.600"
                                      }
                                    />
                                  ) : (
                                    <Icon
                                      as={TriangleDownIcon}
                                      transform="rotate(-90deg)"
                                      width="0.5rem"
                                      height="0.5rem"
                                      color={
                                        selectedInbox === t.id
                                          ? colorMode === "dark"
                                            ? `${colorScheme}.200`
                                            : `${colorScheme}.600`
                                          : colorMode === "dark"
                                          ? "gray.200"
                                          : "gray.600"
                                      }
                                    />
                                  )}
                                  <Tooltip label={t.name}>
                                    <Text
                                      textAlign="left"
                                      flex={1}
                                      textOverflow="ellipsis"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      fontWeight={
                                        getUnreadTotalForTeam(t.id, unreadCount)
                                          ? "extrabold"
                                          : "normal"
                                      }
                                    >
                                      {t.name}
                                    </Text>
                                  </Tooltip>
                                  {getUnreadTotalForTeam(t.id, unreadCount) ? (
                                    <Badge colorScheme="red" mr={2}>
                                      {getUnreadTotalForTeam(
                                        t.id,
                                        unreadCount
                                      ) > 100
                                        ? "100+"
                                        : getUnreadTotalForTeam(
                                            t.id,
                                            unreadCount
                                          )}
                                    </Badge>
                                  ) : null}
                                </AccordionButton>
                                <AccordionPanel
                                  p={0}
                                  pr={2}
                                  display="flex"
                                  flexDirection="column"
                                  gridGap={1}
                                >
                                  {canManageTeamInbox(
                                    merchant.id,
                                    currentAgent!
                                  ) ? (
                                    <Button
                                      pl={7}
                                      variant="ghost"
                                      colorScheme={colorScheme}
                                      justifyContent="start"
                                      w="100%"
                                      borderLeftRadius={0}
                                      isActive={
                                        activeTabIndex === 0 &&
                                        selectedInbox === t.id
                                      }
                                      onClick={() => {
                                        if (
                                          selectedInbox === t.id &&
                                          activeTab === ConversationTab.Team
                                        ) {
                                          return;
                                        }

                                        dispatch(fetchConversations());
                                        setSearchParams({
                                          ...Object.fromEntries(
                                            currentSearchParameters.entries()
                                          ),
                                          team: t.id,
                                          tab: getTabName(0),
                                        });
                                      }}
                                      leftIcon={
                                        <Icon
                                          as={AllConversationsIcon}
                                          mr={1}
                                          width="1.35rem"
                                          height="1.35rem"
                                          __css={{
                                            path: {
                                              stroke:
                                                activeTabIndex === 0 &&
                                                selectedInbox === t.id
                                                  ? colorMode === "dark"
                                                    ? `${colorScheme}.200`
                                                    : `${colorScheme}.600`
                                                  : colorMode === "dark"
                                                  ? "gray.200"
                                                  : "gray.600",
                                            },
                                          }}
                                        />
                                      }
                                    >
                                      <Text
                                        textAlign="left"
                                        w="100%"
                                        color={
                                          activeTabIndex === 0 &&
                                          selectedInbox === t.id
                                            ? colorMode === "dark"
                                              ? `${colorScheme}.200`
                                              : `${colorScheme}.600`
                                            : colorMode === "dark"
                                            ? "gray.200"
                                            : "gray.600"
                                        }
                                      >
                                        All
                                      </Text>
                                      {unreadCount?.teams &&
                                      unreadCount.teams[t.id]?.shared ? (
                                        <Badge ml={1} colorScheme="red">
                                          {unreadCount.teams[t.id]?.shared > 100
                                            ? "100+"
                                            : unreadCount.teams[t.id]?.shared}
                                        </Badge>
                                      ) : null}
                                    </Button>
                                  ) : null}
                                  <Button
                                    pl={7}
                                    variant="ghost"
                                    colorScheme={colorScheme}
                                    justifyContent="start"
                                    w="100%"
                                    borderLeftRadius={0}
                                    isActive={
                                      activeTabIndex === 1 &&
                                      selectedInbox === t.id
                                    }
                                    onClick={() => {
                                      if (
                                        selectedInbox === t.id &&
                                        activeTab === ConversationTab.Personal
                                      ) {
                                        return;
                                      }

                                      dispatch(fetchConversations());
                                      setSearchParams({
                                        ...Object.fromEntries(
                                          currentSearchParameters.entries()
                                        ),
                                        team: t.id,
                                        tab: getTabName(1),
                                      });
                                    }}
                                    leftIcon={
                                      <Icon
                                        as={PersonalConversationsIcon}
                                        mr={1}
                                        width="1.5rem"
                                        height="1.5rem"
                                        __css={{
                                          path: {
                                            stroke:
                                              activeTabIndex === 1 &&
                                              selectedInbox === t.id
                                                ? colorMode === "dark"
                                                  ? `${colorScheme}.200`
                                                  : `${colorScheme}.600`
                                                : colorMode === "dark"
                                                ? "gray.200"
                                                : "gray.600",
                                          },
                                        }}
                                      />
                                    }
                                  >
                                    <Text
                                      textAlign="left"
                                      w="100%"
                                      color={
                                        activeTabIndex === 1 &&
                                        selectedInbox === t.id
                                          ? colorMode === "dark"
                                            ? `${colorScheme}.200`
                                            : `${colorScheme}.600`
                                          : colorMode === "dark"
                                          ? "gray.200"
                                          : "gray.600"
                                      }
                                    >
                                      Personal
                                    </Text>
                                    {unreadCount?.teams &&
                                    unreadCount.teams[t.id]?.personal ? (
                                      <Badge ml={1} colorScheme="red">
                                        {unreadCount.teams[t.id]?.personal > 100
                                          ? "100+"
                                          : unreadCount.teams[t.id]?.personal}
                                      </Badge>
                                    ) : null}
                                  </Button>
                                  <Button
                                    pl={7}
                                    justifyContent="start"
                                    colorScheme={colorScheme}
                                    variant="ghost"
                                    w="100%"
                                    borderLeftRadius={0}
                                    isActive={
                                      activeTabIndex === 2 &&
                                      selectedInbox === t.id
                                    }
                                    onClick={() => {
                                      if (
                                        selectedInbox === t.id &&
                                        activeTab === ConversationTab.Unassigned
                                      ) {
                                        return;
                                      }

                                      dispatch(fetchConversations());
                                      setSearchParams({
                                        ...Object.fromEntries(
                                          currentSearchParameters.entries()
                                        ),
                                        team: t.id,
                                        tab: getTabName(2),
                                      });
                                    }}
                                    leftIcon={
                                      <Icon
                                        as={UnassignedConversationsIcon}
                                        mr={1}
                                        width="1.5rem"
                                        height="1.5rem"
                                        __css={{
                                          path: {
                                            stroke:
                                              activeTabIndex === 2 &&
                                              selectedInbox === t.id
                                                ? colorMode === "dark"
                                                  ? `${colorScheme}.200`
                                                  : `${colorScheme}.600`
                                                : colorMode === "dark"
                                                ? "gray.200"
                                                : "gray.600",
                                          },
                                        }}
                                      />
                                    }
                                  >
                                    <Text
                                      textAlign="left"
                                      w="100%"
                                      color={
                                        activeTabIndex === 2 &&
                                        selectedInbox === t.id
                                          ? colorMode === "dark"
                                            ? `${colorScheme}.200`
                                            : `${colorScheme}.600`
                                          : colorMode === "dark"
                                          ? "gray.200"
                                          : "gray.600"
                                      }
                                    >
                                      Unassigned
                                    </Text>
                                    {unreadCount?.teams &&
                                    unreadCount.teams[t.id]?.unassigned ? (
                                      <Badge ml={1} colorScheme="red">
                                        {unreadCount.teams[t.id]?.unassigned >
                                        100
                                          ? "100+"
                                          : unreadCount.teams[t.id]?.unassigned}
                                      </Badge>
                                    ) : null}
                                  </Button>
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ) : null}
          </Accordion>
          {canManageTeamInbox(merchant.id, currentAgent!) ? (
            <Box w="100%" pr={2}>
              <Button
                w="100%"
                borderLeftRadius={0}
                colorScheme={colorScheme}
                variant="ghost"
                leftIcon={<Icon as={PlusSquareIcon} />}
                justifyContent="start"
                onClick={() => {
                  setShowAddTeamModal(true);
                }}
              >
                Add Team
              </Button>
              <AddTeamModal
                isOpen={showAddTeamModal}
                onClose={() => setShowAddTeamModal(false)}
              />
            </Box>
          ) : null}
        </VStack>
        <Flex
          w="100%"
          h="100%"
          overflowY="hidden"
          borderTopRadius="3xl"
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          flexDirection="column"
        >
          <Conversations />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      direction="row"
      overflow="hidden"
      height="100%"
      width="100%"
      minWidth="100%"
      alignItems="start"
      pl={4}
    >
      <Flex
        w="100%"
        h="100%"
        overflowY="hidden"
        borderTopRadius="3xl"
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        flexDirection="column"
      >
        <Conversations />
      </Flex>
    </Flex>
  );
};

export default DesktopTabsArea;
