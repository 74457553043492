import {
  Box,
  Divider,
  Flex,
  Text,
  SkeletonText,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import SmartList from "components/shared/SmartList";
import React, { useMemo } from "react";
import { ExtendedLeadCountBySourceDomain } from "entities/domain/leads/lead-count-by-source-domain";

interface ListProps {
  reports: ExtendedLeadCountBySourceDomain[];
  isLoading: boolean;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const SourceNameColumn = ({
  item,
}: {
  item: ExtendedLeadCountBySourceDomain;
}) => <Text>{item.source === null ? "N/A" : item.source}</Text>;

const SourceCountColumn = ({
  item,
}: {
  item: ExtendedLeadCountBySourceDomain;
}) => <Text>{item.count === null ? 0 : item.count}</Text>;

const List: React.FC<ListProps> = ({
  reports,
  isLoading,
  scrollContainerRef,
}) => {
  const { colorMode } = useColorMode();

  const memoizedColumns = useMemo(() => {
    return [
      {
        label: "Source",
        component: SourceNameColumn,
      },
      {
        label: "Number of Leads",
        component: SourceCountColumn,
      },
    ];
  }, []);

  return isLoading ? (
    <VStack w="100%" h="100%">
      {[...Array(5).keys()].map((i) => (
        <>
          {i === 0 ? (
            <Box
              mt={4}
              height="2.5rem"
              width="100%"
              py={2}
              px={4}
              bgColor={colorMode === "dark" ? "gray.700" : "gray.100"}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
            >
              <SkeletonText noOfLines={1} skeletonHeight={4} width="8rem" />
            </Box>
          ) : (
            <Divider />
          )}
          <Flex
            key={`skeleton-payment-${i}`}
            padding="6"
            w="100%"
            h="fit-content"
            gridGap={4}
          >
            <SkeletonText
              noOfLines={2}
              spacing={4}
              skeletonHeight={2}
              flexShrink={1}
              flexGrow={1}
            />
          </Flex>
        </>
      ))}
    </VStack>
  ) : (
    <SmartList
      items={reports}
      itemIdentifier="index"
      containerRef={scrollContainerRef}
      columns={memoizedColumns}
    />
  );
};

export default List;
