import { Icon as ChakraIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import TemplateDomain from "entities/domain/templates";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  activeConversationTemplatesSelector,
  propagateTemplateUpdate,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Color } from "theme/old-design-system/styled-components";
import { messageTemplateInsertion$ } from "util/constants";
import TemplateItem from "./TemplateItem";

interface TemplatesPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

const TemplatesPopover = ({
  onClose,
  isOpen,
  ...rest
}: TemplatesPopoverProps) => {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [searchValue, setSearchValue] = useState<string>("");
  const { markTemplateAsFavouriteOrGeneral } = useTemplatesStore();
  const { errors } = useAppSelector((state) => state.templates);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const popoverContent = useRef<HTMLDivElement>(null);
  const conversationTemplates = useAppSelector(
    activeConversationTemplatesSelector
  );
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const [filteredTemplates, setFilteredTemplates] = useState<TemplateDomain[]>(
    conversationTemplates.filter((t: TemplateDomain) =>
      t.title.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  useEffect(() => {
    return () => {
      setSearchValue("");
    };
  }, []);

  useEffect(() => {
    if (!popoverContent || !popoverContent.current) {
      return;
    }

    popoverContent.current.scrollTop = 0;
    setSearchValue("");
  }, [isOpen, popoverContent]);

  useEffect(() => {
    if (errors.length) {
      toast({ status: "error", title: errors[0] });
    }
  }, [errors]);

  useEffect(() => {
    setFilteredTemplates(
      conversationTemplates.filter((t: TemplateDomain) =>
        t.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [conversationTemplates, searchValue]);

  const findTemplateById = (templateId: string) => {
    return conversationTemplates.find(
      (t: TemplateDomain) => t.id === templateId
    );
  };

  const onTemplateClick = (templateId: string) => {
    const template = findTemplateById(templateId);

    onClose();

    if (!template) return;

    messageTemplateInsertion$.next({
      id: template.id,
      name: template.name,
      title: template.title,
      text: template.text,
      customFields: template.customFields,
      shortcut: template.shortcut,
      subject: template.subject,
      favourite: template.favourite,
      channels: template.channels,
      mediaType: template.mediaType,
      mediaUrl: template.mediaUrl,
      buttons: template.buttons,
    });
  };

  const onMakeFavouriteClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        true
      );

      if (!updatedTemplate) return;

      if (activeConversationId) {
        dispatch(
          propagateTemplateUpdate({
            template: updatedTemplate as TemplateDomain,
            conversationId: activeConversationId,
          })
        );
      }
    },
    [
      conversationTemplates,
      markTemplateAsFavouriteOrGeneral,
      activeConversationId,
    ]
  );

  const onMakeGeneralClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        false
      );

      if (!updatedTemplate) return;

      if (activeConversationId) {
        dispatch(
          propagateTemplateUpdate({
            template: updatedTemplate as TemplateDomain,
            conversationId: activeConversationId,
          })
        );
      }
    },
    [
      conversationTemplates,
      markTemplateAsFavouriteOrGeneral,
      activeConversationId,
    ]
  );

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  return (
    <Box w="100%" borderRadius="lg" overflow="hidden">
      <Box
        w="100%"
        boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
        maxHeight="50vh"
        overflowY="auto"
        sx={{
          "#templates-search-input": {
            borderRadius: "full",
            backgroundColor: colorMode === "dark" ? "gray.900" : "white",
          },
        }}
        ref={popoverContent}
        {...rest}
      >
        <Flex
          pt={2}
          px={4}
          mb={4}
          w="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading as="h4" size="sm">
            Templates
          </Heading>
          <Flex gridGap={3} alignItems="center" justifyContent="end">
            {!isBaseSize && (
              <Link to={`/${merchant.id}/settings/templates`}>
                <ChakraIcon
                  as={ReactSVG}
                  src="/fi-rr-settings.svg"
                  css={{
                    svg: {
                      width: "1.25rem",
                      height: "1.25rem",
                      g: {
                        path: {
                          fill: `${Color.DARK_GREY.value}`,
                        },
                      },
                    },
                  }}
                />
              </Link>
            )}
            <IconButton
              aria-label="Close Templates Popover"
              onClick={() => onClose()}
              icon={<CloseIcon />}
              variant="ghost"
              isRound={true}
              colorScheme="gray"
            />
          </Flex>
        </Flex>

        <InputGroup w="100%" px={4} mb={4}>
          <Input
            colorScheme={colorScheme}
            id="templates-search-input"
            background="gray.50"
            value={searchValue}
            // @ts-ignore
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
          />
          <InputRightElement mr={4}>
            <Icon as={Search2Icon} />
          </InputRightElement>
        </InputGroup>

        <List
          width="100%"
          display="flex"
          flexFlow="column nowrap"
          listStyleType="none"
        >
          {filteredTemplates.map(
            ({ id, title, text, favourite }: TemplateDomain, i) => (
              <TemplateItem
                id={id}
                isFavourite={favourite}
                title={title}
                text={text}
                key={i.toString()}
                onClick={onTemplateClick}
                onMakeFavourite={onMakeFavouriteClick}
                onMakeGeneral={onMakeGeneralClick}
              />
            )
          )}
        </List>
      </Box>
    </Box>
  );
};

export default TemplatesPopover;
