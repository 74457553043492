import { Auth0ContextInterface } from "@auth0/auth0-react";
import AttributeDomain, {
  AttributeType,
} from "entities/domain/attributes/attribute-domain";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  request,
} from "util/methods";
import { AttributeDTO } from "entities/dto/AttributeDTO";
import {
  attributeArrayTransformFromDtoToDomain,
  attributeTransformFromDtoToDomain,
} from "entities/transformers/attribute-transformers";
import {
  CreateAttributePayload,
  UpdateAttributePayload,
} from "redux/features/attributes";
import { RequestType } from "./request-type";

class AttributeService {
  public static async getAttributesForGroup(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string
  ): Promise<AttributeDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchant-groups/${groupId}/attributes`
    );

    const attributes: AttributeDTO[] = data.data;

    return attributeArrayTransformFromDtoToDomain(attributes);
  }

  public static async createAttribute(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string,
    createAttributePayload: CreateAttributePayload
  ): Promise<AttributeDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await mutationRequest(
      RequestType.POST,
      accessToken,
      `/merchant-groups/${groupId}/attributes`,
      createAttributePayload
    );

    const attribute: AttributeDTO = data.data;

    return attributeTransformFromDtoToDomain(attribute);
  }

  public static async updateAttribute(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string,
    attributeId: string,
    updateAttributePayload: UpdateAttributePayload
  ): Promise<AttributeDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/merchant-groups/${groupId}/attributes/${attributeId}`,
      updateAttributePayload
    );

    const attribute: AttributeDTO = data.data;

    return attributeTransformFromDtoToDomain(attribute);
  }

  public static async deleteAttribute(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string,
    attributeId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchant-groups/${groupId}/attributes/${attributeId}`
    );
  }
}

export default AttributeService;
