export default class LeadCountBySourceDomain {
  merchantId: number;
  dateFrom: string | null;
  dateTo: string | null;
  source: string;
  count: number;

  constructor(
    merchantId: number,
    source: string,
    dateFrom: string | null,
    dateTo: string | null,
    count: number
  ) {
    this.merchantId = merchantId;
    this.source = source;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.count = count;
  }
}

// Smartlist needs key
export interface ExtendedLeadCountBySourceDomain
  extends LeadCountBySourceDomain {
  index: number;
}
