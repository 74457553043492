import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import FullScreenSpinner from "./components/spinner/full-screen-spinner";

function Auth0Wrapper({ children }: { children: ReactNode }) {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  if (error && error.message.includes("Invalid state")) {
    window.location.href = "/";

    return null;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return <>{children}</>;
}

export default Auth0Wrapper;
