import { getDisplayPrettyDate } from "util/methods";
import AttachmentDomain from "../attachments/attachment-domain";
import { ConversationChannel } from "./conversation-domain";
import MessageFailedReason from "./message-errors";

export enum MESSAGE_ERRORS {
  EXTERNAL_PROVIDER_ERROR = "EXTERNAL_PROVIDER_ERROR",
}

export enum MessageStatus {
  ACCEPTED = "accepted",
  QUEUED = "queued",
  SENDING = "sending",
  SENT = "sent",
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  FAILED = "failed",
  RECEIVING = "receiving",
  RECEIVED = "received",
  READ = "read",
  UNKNOWN = "unknown",
}

export enum StatusIcon {
  IN_PROGRESS = "in_progress", // GRAY
  DELIVERED = "delivered",
  SUCCESS = "success", // BLUE
  FAILED = "failed", // RED
  NONE = "none", // DON'T SHOW
}

export enum MessageType {
  TEXT = "TEXT",
  NOTIFICATION = "NOTIFICATION",
  INFO_CARD = "INFO_CARD",
  TEMPLATE = "TEMPLATE",
}

export enum MessageSubType {
  REGULAR = "REGULAR",
  WEBSITE_ENQUIRY = "WEBSITE_ENQUIRY",
  FINISHED_CALL = "FINISHED_CALL",
  MISSED_CALL = "MISSED_CALL",
  NEW_SUBJECT_LINE = "NEW_SUBJECT_LINE",
  NEW_LEAD = "NEW_LEAD",
  VEHICLE = "VEHICLE",
  EXISTING_LEAD_EXTRA_CHANNEL = "EXISTING_LEAD_EXTRA_CHANNEL",
  CAMPAIGN_MESSAGE = "CAMPAIGN_MESSAGE",
  PAYMENT_REQUEST = "PAYMENT_REQUEST",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
  REVIEW_REQUEST = "REVIEW_REQUEST",
  NEW_AGENT = "NEW_AGENT",
}

export enum AttachmentType {
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image",
  FILE = "file",
}

export enum MessageDirection {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
  UNSPECIFIED = "unspecified",
}

export interface MessageMetadata {
  error_code?: string;
  error_description?: string;
  attributes?: {
    [key: string]: string | number;
  };
}

const convertMimeTypeToType = (
  mimeType?: string
): AttachmentType | undefined => {
  if (!mimeType) {
    return undefined;
  }
  if (mimeType.includes("audio")) {
    return AttachmentType.AUDIO;
  }
  if (mimeType.includes("video")) {
    return AttachmentType.VIDEO;
  }
  if (mimeType.includes("image")) {
    return AttachmentType.IMAGE;
  }
  return AttachmentType.FILE;
};

export enum MessageButtonType {
  URL = "URL",
  PHONE_NUMBER = "PHONE_NUMBER",
  QUICK_REPLY = "QUICK_REPLY",
}

export class MessageButton {
  type: MessageButtonType;
  text: string;
  url?: string;
  phoneNumber?: string;

  constructor(
    type: MessageButtonType,
    text: string,
    url?: string,
    phoneNumber?: string
  ) {
    this.type = type;
    this.text = text;
    this.url = url;
    this.phoneNumber = phoneNumber;
  }
}

export default class MessageDomain {
  id: number;

  body: string;

  isIncoming: boolean;

  isRead: boolean;

  createdAt: Date;

  conversationId: number;

  agentId: number;

  status: MessageStatus;

  type: MessageType;

  subType: MessageSubType;

  title: string | null;

  replyToMessageId: number | null;

  direction: MessageDirection;

  metadata: MessageMetadata | null;

  attachments: AttachmentDomain[];

  rawHtml: string | null;

  buttons?: MessageButton[];

  getStatusIcon(channel: ConversationChannel): StatusIcon {
    if (!this.isIncoming) {
      switch (this.status) {
        case MessageStatus.UNDELIVERED:
        case MessageStatus.FAILED:
          return StatusIcon.FAILED;
        case MessageStatus.ACCEPTED:
        case MessageStatus.QUEUED:
        case MessageStatus.SENDING:
        case MessageStatus.SENT:
          return channel === ConversationChannel.FACEBOOK ||
            channel === ConversationChannel.INSTAGRAM
            ? StatusIcon.DELIVERED
            : StatusIcon.IN_PROGRESS;
        case MessageStatus.DELIVERED:
          return StatusIcon.DELIVERED;
        case MessageStatus.READ:
          return StatusIcon.SUCCESS;
        case MessageStatus.UNKNOWN:
        default:
          return StatusIcon.NONE;
      }
    }

    return StatusIcon.NONE;
  }

  isNotification(): boolean {
    return (
      this.type === MessageType.NOTIFICATION ||
      this.subType === MessageSubType.NEW_SUBJECT_LINE
    );
  }

  isPhoneCall(): boolean {
    return (
      this.subType === MessageSubType.FINISHED_CALL ||
      this.subType === MessageSubType.MISSED_CALL
    );
  }

  isPhoneCallWithoutRecording(): boolean {
    return (
      this.isPhoneCall() && this.getAttachmentType() !== AttachmentType.AUDIO
    );
  }

  isPhoneCallRecording(): boolean {
    return (
      this.isPhoneCall() && this.getAttachmentType() === AttachmentType.AUDIO
    );
  }

  isImageMessage(): boolean {
    return (
      this.getAttachmentType() === AttachmentType.IMAGE && !this.isVehicleInfo()
    );
  }

  isCustomerLeadMessage(): boolean {
    return this.subType === MessageSubType.WEBSITE_ENQUIRY;
  }

  isVideoMessage(): boolean {
    return this.getAttachmentType() === AttachmentType.VIDEO;
  }

  isFileMessage(): boolean {
    return this.getAttachmentType() === AttachmentType.FILE;
  }

  isAudioMessage(): boolean {
    return (
      this.getAttachmentType() === AttachmentType.AUDIO && !this.isPhoneCall()
    );
  }

  hasMultipleAttachments(): boolean {
    return this.attachments.length > 1 && !this.isVehicleInfo();
  }

  isForwardedEmail(): boolean {
    return this.body === "{ Forwarded Message }" && this.rawHtml !== null;
  }

  isTextMessage(): boolean {
    const isNotTextMessage =
      this.isAudioMessage() ||
      this.isImageMessage() ||
      this.isVideoMessage() ||
      this.isFileMessage() ||
      this.isPhoneCallWithoutRecording() ||
      this.isPhoneCallRecording() ||
      this.hasMultipleAttachments() ||
      this.isVehicleInfo() ||
      this.isForwardedEmail();

    return !isNotTextMessage && !!this.body;
  }

  isInfoMessage(): boolean {
    return (
      this.type === MessageType.INFO_CARD &&
      this.subType === MessageSubType.REGULAR &&
      !this.body &&
      !this.attachments.length &&
      !!this.title &&
      !!this.metadata?.attributes &&
      Object.keys(this.metadata?.attributes).length > 0
    );
  }

  getAttachmentType(): AttachmentType | undefined {
    if (this.attachments.length === 1) {
      return convertMimeTypeToType(this.attachments[0].type);
    }

    return undefined;
  }

  isVehicleInfo(): boolean {
    return this.subType === MessageSubType.VEHICLE;
  }

  hasURLs(): boolean {
    if (this.body.length === 0) {
      return false;
    }

    return this.body.includes("http") && !this.isVehicleInfo();
  }

  getTimeStamp(): string {
    return getDisplayPrettyDate(this.createdAt);
  }

  getFontColor(): string {
    return this.isIncoming || this.isPhoneCall() ? "inherit" : "white";
  }

  getFailedReason(): string {
    return this.metadata?.error_description! || MessageFailedReason.default;
  }

  isUnreachable(): boolean {
    if (
      !this.metadata ||
      this.metadata.error_code !== MESSAGE_ERRORS.EXTERNAL_PROVIDER_ERROR ||
      !this.metadata.error_description ||
      this.direction !== MessageDirection.OUTGOING
    ) {
      return false;
    }

    try {
      const providerErrors = JSON.parse(this.metadata.error_description);

      if (!Array.isArray(providerErrors)) {
        return false;
      }

      const hasUnreachableError = providerErrors.some(
        (providerError) =>
          // eslint-disable-next-line
          providerError.hasOwnProperty("code") && providerError.code === 131026 // Whatsapp Code for "Customer doesn't exist"
      );

      return hasUnreachableError;
    } catch (_error: unknown) {
      return false;
    }
  }

  getHtml(): string | null {
    if (!this.rawHtml) {
      return null;
    }

    return atob(this.rawHtml);
  }

  constructor(
    id: number,
    body: string,
    isIncoming: boolean,
    isRead: boolean,
    createdAt: Date,
    conversationId: number,
    agentId: number,
    status: MessageStatus,
    type: MessageType,
    subType: MessageSubType,
    title: string | null,
    replyToMessageId: number | null,
    direction: MessageDirection,
    metadata: MessageMetadata | null,
    attachments: AttachmentDomain[],
    rawHtml: string | null,
    buttons: MessageButton[] | null
  ) {
    this.id = id;
    this.body = body;
    this.isIncoming = isIncoming;
    this.isRead = isRead;
    this.createdAt = createdAt;
    this.conversationId = conversationId;
    this.agentId = agentId;
    this.status = status;
    this.type = type;
    this.subType = subType;
    this.title = title;
    this.replyToMessageId = replyToMessageId;
    this.direction = direction;
    this.metadata = metadata;
    this.attachments = attachments;
    this.rawHtml = rawHtml;
    this.buttons = buttons || undefined;
  }
}
