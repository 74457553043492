import { Box, Stack, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import React from "react";
import { subMonths } from "date-fns";
import ReactDatePicker from "react-datepicker";

interface DateFilterProps {
  fromDate: Date | null;
  toDate: Date | null;
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
}

const DateFilter = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}: DateFilterProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Stack
      py={isBaseSize ? 2 : 4}
      px={isBaseSize ? 4 : 0}
      direction={isBaseSize ? "column" : "row"}
      width={isBaseSize ? "100%" : "auto"}
    >
      <Box
        rounded="full"
        border="none"
        textAlign="left"
        bgColor={
          colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
        }
        px={3}
        css={{
          input: {
            height: isBaseSize ? "2rem" : "2.5rem",
            width: "100%",
            backgroundColor: "inherit",
          },
          ".react-datepicker-wrapper": {
            width: "100%",
          },
        }}
      >
        <ReactDatePicker
          id="analytics-from-date"
          selected={fromDate}
          placeholderText="From"
          onChange={(newDate) => {
            setFromDate(newDate);
          }}
          maxDate={toDate || new Date()}
          minDate={subMonths(toDate || new Date(), 3)}
          showTimeSelect={false}
          dateFormat="yyyy-MM-dd"
        />
      </Box>
      <Box
        rounded="full"
        border="none"
        bgColor={
          colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
        }
        textAlign="left"
        px={3}
        css={{
          input: {
            height: isBaseSize ? "2rem" : "2.5rem",
            width: "100%",
            backgroundColor: "inherit",
          },
          ".react-datepicker-wrapper": {
            width: "100%",
          },
        }}
      >
        <ReactDatePicker
          id="analytics-to-date"
          selected={toDate}
          placeholderText="Until"
          onChange={(newDate) => {
            setToDate(newDate);
          }}
          maxDate={new Date()}
          minDate={fromDate || subMonths(new Date(), 3)}
          showTimeSelect={false}
          dateFormat="yyyy-MM-dd"
        />
      </Box>
    </Stack>
  );
};

export default DateFilter;
